import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import List from "@material-ui/core/List";
import _ from "lodash";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import errorActions from "../actions/error";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import PropTypes from 'prop-types';
import guestsActions from "../actions/guests";

const mapStateToProps = state => ({
    guests: state.guests,
    error: state.error.message,
});

const mapDispatchToProps = {
    setError: errorActions.setError,
    hideError: errorActions.hideError,
    addGuest: guestsActions.addGuest,
};

class SelectGuestDialog extends React.PureComponent {

    handleAddGuest = () => {
        this.props.addGuest({name: 'Новый гость'}, true)
    }

    handleChooseGuest = (event) => {
        this.props.handleChooseGuest(event.currentTarget.dataset.guestKey)
    }

    render() {
        const {guests, exclude} = this.props;
        let guestsFiltered = guests;
        if (!_.isEmpty(exclude)) {
            guestsFiltered = guestsFiltered.filter((guest) => !exclude.includes(guest.key))
        }

        return (
            <Dialog
                open={this.props.isOpen}
                onClose={this.props.handleClose}
            >
                <DialogTitle>Выберите гостя</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {!_.isEmpty(guestsFiltered)
                            ?
                            <List>
                                {guestsFiltered.map((guest) =>
                                    <ListItem
                                        key={guest.key}
                                        button
                                        onClick={this.handleChooseGuest}
                                        data-guest-key={guest.key}
                                    >
                                        <ListItemText primary={guest.name}/>
                                    </ListItem>
                                )}
                            </List>
                            : <Typography
                                align={'center'}
                                color={'textSecondary'}
                            >
                                Нет гостей
                            </Typography>
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {this.props.allowAddGuest ?
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<PersonAddIcon/>}
                            onClick={this.handleAddGuest}
                            fullWidth={true}
                        >
                            Добавить гостя
                        </Button>
                    : null}

                    {this.props.handleChooseGuestConfirm ?
                        <Button onClick={this.props.handleChooseGuestConfirm} color="primary" autoFocus>
                            Agree
                        </Button>
                    : null}
                </DialogActions>
            </Dialog>
        );
    }
}

SelectGuestDialog.propTypes = {
    exclude: PropTypes.array,
    allowAddGuest: PropTypes.bool,
    isOpen: PropTypes.bool.isRequired,
    handleChooseGuest: PropTypes.func.isRequired,
    handleChooseGuestConfirm: PropTypes.func,
    handleClose: PropTypes.func,
}

SelectGuestDialog.defaultProps = {
    exclude: [],
    allowAddGuest: false,
    isOpen: false,
    handleChooseGuest: (guestKey) => () => console.log('handleChooseGuest guestKey: ' + guestKey),
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SelectGuestDialog));