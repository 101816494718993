import React from 'react';
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

class AcceptButton extends React.PureComponent {

    render() {
        const {...other} = this.props

        return (
            <Box position={'sticky'}
                 bottom={0}
                 style={{
                     padding: '10px 10px 10px 10px',
                     backgroundColor: "white",
                     boxShadow: '0 -1px 3px gray'
                 }}
            >
                <Button
                    {...other}
                >
                    {this.props.children}
                </Button>
            </Box>
        );
    }
}

export default AcceptButton;