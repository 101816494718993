import {guestsActionTypes} from "../actions/guests";

const initialState = []

export const emptyGuest = {
    key: 0,
    id: null,
    name: "",
    phone: "",
    email: "",
    isMain: false,
    comment: "",
    phoneCode: null,
    agreementPolicy: true,
    isSecond: false,
}

export default function guests(state = initialState, action) {
    switch (action.type) {
        case guestsActionTypes.ADD_GUEST:
            const newKey = state.length + 1;
            const newGuest = {
                ...emptyGuest,
                ...action.guest,
                key: newKey,
            }

            if(action.setNumber) {
                newGuest.name = `${action.guest.name} ${newKey}`
            }

            return [
                ...state,
                newGuest
            ];

        case guestsActionTypes.REMOVE_GUEST:
            return state.filter(guest => guest.key !== action.guestKey)

        case guestsActionTypes.SET_GUEST_PROPERTY:
            return state.map(guest => {
                if (guest.key === action.guestKey) {
                    return {
                        ...guest,
                        [action.property]: action.value
                    }
                }
                return guest
            })


        case guestsActionTypes.SET_DURATION:
            return state.map(guest => {
                if(guest.key === action.guestKey) {
                    return {
                        ...guest,
                        duration: action.duration
                    }
                }
                return guest
            })

        case guestsActionTypes.SET_MAIN_GUEST:
            return state.map(guest => {
                if(guest.key === action.guestKey) {
                    return {
                        ...guest,
                        isMain: true,
                    }
                }
                return {
                    ...guest,
                    isMain: false
                }
            })

        case guestsActionTypes.RESET:
            return initialState
        default:
            return state;
    }
}

