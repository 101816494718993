import facilitiesActionTypes from "../actions/facilities";
import _ from 'lodash'

const initialState = {}

export const emptyFacility = {
    id: null,
    name: null,
    description: null,
    selected: false,
    duration: 0,
    price: null,
    priceCosmetic: null,
    priceId: null,
    facilityPriceId: null,
    masters: [],
    disabled: false,
    hide: false,
    masterSpecializations: [],
    guestsCount: 0,
    mastersCount: 0,
    durations: [],
    typeText: null,
    slim: false,
    secondGuest: null
}

export default function facilities(state = initialState, action) {
    switch (action.type) {
        case facilitiesActionTypes.SET_FACILITIES:
            return {
                ...state,
                [action.guestKey]: action.facilities
                    .filter(facility => {
                        return !!facility.price.find(p => p.priceId === action.priceId)
                    })
                    .map(facility => {
                        const price = facility.price.find(p => p.priceId === action.priceId)
                        const firstDuration = _.first(price.durations)
                        return {
                            ...emptyFacility,
                            id: facility.id,
                            name: facility.name,
                            description: facility.description,
                            price: firstDuration.widgetPrice,
                            priceCosmetic: firstDuration.priceCosmetic,
                            priceId: firstDuration.priceId,
                            facilityPriceId: firstDuration.id,
                            duration: firstDuration.time,
                            minDuration: firstDuration.time,
                            maxDuration: _.last(price.durations).time,
                            masterSpecializations: facility.masterSpecializations,
                            guestsCount: facility.guestsCount,
                            mastersCount: facility.mastersCount,
                            durations: price.durations,
                            typeText: facility.typeText,
                            slim: facility.slim
                        }
                    })
            }

        case facilitiesActionTypes.CHANGE_SELECTED:
            return {
                ...state,
                [action.guestKey]: state[action.guestKey].map(facility => {
                    if (facility.id === action.id) {
                        return {
                            ...facility,
                            selected: !facility.selected,
                        }
                    }
                    return facility
                })
            }

        case facilitiesActionTypes.FILTER:
            const selectedFacilities = state[action.guestKey].filter(f => f.selected)

            const allowedMasterSpecializations = selectedFacilities.reduce((accumulator, currentValue) => {
                currentValue.masterSpecializations.forEach(masterSpecialization => {
                    if (!accumulator.includes(masterSpecialization)) {
                        accumulator.push(masterSpecialization)
                    }
                })
                return accumulator;
            }, [])

            const allowedMastersCount = selectedFacilities.reduce((accumulator, currentValue) => {
                if (!accumulator.includes(currentValue.mastersCount)) {
                    accumulator.push(currentValue.mastersCount)
                }
                return accumulator;
            }, [])

            const allowedGuestsCount = selectedFacilities.reduce((accumulator, currentValue) => {
                if (!accumulator.includes(currentValue.guestsCount)) {
                    accumulator.push(currentValue.guestsCount)
                }
                return accumulator;
            }, [])


            return {
                ...state,
                [action.guestKey]: state[action.guestKey].map(facility => {
                    if (
                        !_.isEmpty(_.difference(allowedMasterSpecializations, facility.masterSpecializations)) ||
                        !_.isEmpty(_.difference(allowedMastersCount, [facility.mastersCount])) ||
                        !_.isEmpty(_.difference(allowedGuestsCount, [facility.guestsCount])) ||
                        action.maxDuration <= action.guestDuration ||
                        action.guestDuration + facility.durations[0].time > action.maxDuration ||
                        (selectedFacilities.length > 0 && selectedFacilities[0].slim)
                    ) {
                        return {
                            ...facility,
                            disabled: true,
                            hide: facility.minDuration > action.maxDuration
                        }
                    }

                    return {
                        ...facility,
                        disabled: false,
                        hide: facility.minDuration > action.maxDuration
                    }
                })
            }

        case facilitiesActionTypes.CHANGE_FACILITY:
            return {
                ...state,
                [action.guestKey]: state[action.guestKey].map(facility => {
                    if (facility.id === action.facility.id) {
                        return {
                            ...facility,
                            ...action.facility
                        }
                    }
                    return facility
                })
            }

        case facilitiesActionTypes.REMOVE_GUEST_FACILITIES:
            const newState = {};
            _.forIn(state, (value, key) => {
                if (parseInt(key) !== action.guestKey) {
                    newState[key] = value
                }
            })
            return newState;

        case facilitiesActionTypes.RESET:
            return initialState;

        default:
            return state;
    }
}

