import React from 'react';
import Container from "@material-ui/core/Container";
import Header from "../components/Header";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import errorActions from '../actions/error';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import authActions from "../actions/auth";
import SendIcon from '@material-ui/icons/Send';
import Button from "@material-ui/core/Button";
import {push} from 'connected-react-router';
import guestsActions from "../actions/guests";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Countdown from "react-countdown";
import {Tooltip, Typography} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import ReplayIcon from '@material-ui/icons/Replay';
import MuiPhoneNumber from "material-ui-phone-number";
import restActions from "../actions/rest";

const mapStateToProps = state => ({
    error: state.error.message,
    guest: state.guests.find(guest => guest.isMain),
    auth: state.auth,
});
const mapDispatchToProps = {
    setError: errorActions.setError,
    hideError: errorActions.hideError,
    sendSms: authActions.sendCode,
    checkCode: authActions.checkCode,
    push: push,
    setGuestProperty: guestsActions.setGuestProperty,
    fetchUnclosedVisitsCheck: restActions.fetchUnclosedVisitsCheck
};

class PhoneCheck extends React.Component {

    state = {
        phoneCode: '',
        isValid: false,
        countDown: 60 * 1000,
    }

    countDownApi;

    componentDidMount() {
            this.props.sendSms()
    }

    handleChangePhoneCode = (event) => {
        const phoneCode = event.target.value;
        const isValid = phoneCode.length === 4
        this.setState({
            phoneCode,
            isValid
        })
    }

    handleCheckCode = () => {
        this.props.checkCode(this.state.phoneCode).then(() => {
            this.props.fetchUnclosedVisitsCheck()
        })
    }

    handleChangeAgreementPolicy = (event) => {
        this.props.setGuestProperty(this.props.guest.key, 'agreementPolicy', event.currentTarget.checked)
    }

    handleSendAgain = () => {
        this.props.sendSms()
        this.setState({countDown: 60 * 1000}, () => {
            this.countDownApi.start()
        })
    }

    handleChangeGuestPhone = value => {
        this.props.setGuestProperty(this.props.guest.key, 'phone', value)
    }


    render() {
        const {guest} = this.props
        const {phoneCode, isValid} = this.state
        return (
            <Container maxWidth="sm" disableGutters>
                <Header/>
                <Paper variant="outlined" square style={{padding: 20}}>
                    <Grid container spacing={2} alignItems={"flex-end"}>

                        <Grid item xs={12} sm={12} md={6}>
                            <MuiPhoneNumber
                                label={'Телефон'}
                                defaultCountry={'ru'}
                                value={guest.phone}
                                onChange={this.handleChangeGuestPhone}
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextField
                                name='phoneCode'
                                label={'Код из СМС'}
                                type='tel'
                                value={phoneCode}
                                onChange={this.handleChangePhoneCode}
                                fullWidth={true}
                                InputProps={{
                                    endAdornment: <InputAdornment position="start">
                                        <Countdown
                                            date={Date.now() + this.state.countDown}
                                            onTick={({total}) => this.setState({countDown: total})}
                                            ref={(countDown) => {
                                                if(countDown) this.countDownApi = countDown.getApi()
                                            }}
                                            renderer={
                                                ({hours, minutes, seconds, completed}) => {
                                                    if (completed) {
                                                        return (
                                                            <Tooltip title={'Отправить повторно'}>
                                                                <IconButton color="primary"
                                                                            onClick={this.handleSendAgain}>
                                                                    <ReplayIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        );
                                                    } else {
                                                        return (
                                                            <Tooltip title={`Отправить повторно через ${seconds}`}>
                                                                <Typography
                                                                    variant={"body1"}
                                                                    color={"textSecondary"}
                                                                    style={{padding: '0 15px 0 10px'}}
                                                                    component={'div'}
                                                                >
                                                                    {seconds}
                                                                </Typography>
                                                            </Tooltip>
                                                        );
                                                    }
                                                }
                                            }/>
                                    </InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Button
                                variant="outlined"
                                color="primary"
                                startIcon={<SendIcon/>}
                                onClick={this.handleCheckCode}
                                fullWidth={true}
                                disabled={!isValid || !guest.agreementPolicy}
                            >
                                Отправить
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl>
                                <FormControlLabel
                                    control={<Checkbox checked={guest.agreementPolicy}
                                                       onChange={this.handleChangeAgreementPolicy}
                                                       name="agreementPolicy"/>}
                                    /* eslint-disable-next-line react/jsx-no-target-blank */
                                    label={<a target="_blank"
                                              href="https://tvoitaispa.ru/upload/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8_%D0%A2%D0%92%D0%9E%D0%99%D0%A2%D0%90%D0%99.pdf">Я
                                        согласен на обработку персональных данных</a>}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PhoneCheck));