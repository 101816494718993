import {Route, withRouter} from 'react-router';
import { connect } from 'react-redux';
import {withTranslation} from "react-i18next";

const mapStateToProps = state => ({
    state: state
});

const mapDispatchToProps = {};

class RouteWithVerification extends Route {

    componentDidMount() {
        if (!this.isVerified()) {
            this.props.history.push("/");
        }
    }

    isVerified = () => {
        const {verification, state} = this.props;
        return verification(state);
    };

    render() {
        if (this.isVerified()) {
            return Route.prototype.render.call(this);
        }

        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(RouteWithVerification)));