import React from 'react';
import Container from "@material-ui/core/Container";
import Header from "../components/Header";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {DatePicker} from "@material-ui/pickers";
import sessionActions from '../actions/session'
import restActions from "../actions/rest";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import {push} from "connected-react-router";

const mapStateToProps = state => ({
    parlour: state.session.parlour,
    date: state.session.date,
    getParamDate: state.get.date,
    notAvailableDays: state.rest.notAvailableDays
});
const mapDispatchToProps = {
    setDate: sessionActions.setDate,
    getNotAvailableDays: restActions.fetchNotAvailableDays,
    push: push,
};

class Guests extends React.Component {

    state = {
        maxDate: moment().add(30, 'days')
    }

    componentDidMount() {
        const {maxDate} = this.state;

        if(this.props.getParamDate) {
            this.props.setDate(moment(this.props.getParamDate).format('YYYY-MM-DD'))
            this.props.push('/guests')
        }

        this.props.getNotAvailableDays(
            moment().format('YYYY-MM-DD'),
            maxDate.format('YYYY-MM-DD'),
        )
    }

    handleSelectDate = date => {
        this.props.setDate(date.format('YYYY-MM-DD'))
        this.props.history.push('/guests')
    }

    filterDates = date => {
        const {notAvailableDays} = this.props;
        const currentDay = notAvailableDays.find((item) => {
            return moment(item.day).diff(date, 'days') === 0;
        })
        return !!currentDay;
    }

    render() {
        const {date} = this.props;
        const {maxDate} = this.state;
        return (
            <Container maxWidth="sm" disableGutters>
                <Header allowedGoBack/>
                <Grid
                    container
                    direction="row"
                    justify="center"
                >
                    <Grid item xs={12}>
                        <DatePicker
                            autoOk
                            variant="static"
                            openTo="date"
                            value={date}
                            maxDate={maxDate}
                            disablePast={true}
                            shouldDisableDate={this.filterDates}
                            onChange={this.handleSelectDate}
                        />
                    </Grid>
                </Grid>
            </Container>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Guests));
