import React from 'react';
import Container from "@material-ui/core/Container";
import Header from "../components/Header";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Button from "@material-ui/core/Button";
import sessionAction from '../actions/session'
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import _ from 'lodash'
import errorActions from "../actions/error";
import guestsActions from "../actions/guests";
import {push} from 'connected-react-router';
import {facilitiesActions} from "../actions/facilities";
import GuestCard from "../components/GuestCard";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import getSelectedFacilities from "../selectros/facilities";
import restActions from "../actions/rest";
import AcceptButton from "../components/AcceptButton";

const mapStateToProps = state => ({
    guests: state.guests,
    facilities: state.facilities,
    maxGuestCount: state.session.maxGuestCount,
    auth: state.auth,
    isLoading: state.rest.isLoading
});

const mapDispatchToProps = {
    addGuest: guestsActions.addGuest,
    removeGuest: guestsActions.removeGuest,
    setGuestProperty: guestsActions.setGuestProperty,
    calcDuration: guestsActions.calcDuration,
    setError: errorActions.error,
    hideError: errorActions.hideError,
    push: push,
    setMainGuest: guestsActions.setMainGuest,
    changeSelectedFacility: facilitiesActions.changeSelected,
    getSelectedFacilities: getSelectedFacilities,
    getMasters: restActions.fetchWorkingMasters,
    setParlour: sessionAction.setParlour,
};


class Guests extends React.Component {

    componentDidMount() {
        if(this.props.guests.length === 0)
            this.props.addGuest()
    }

    handleAddGuest = () => {
        this.props.addGuest()
    }

    handleAccept = () => {
        const {guests, facilities} = this.props;
        const guestWithOutFacilities = guests.filter(guest => {
            return (
                (!facilities[guest.key] || facilities[guest.key].filter(f => f.selected).length === 0)
                &&
                !guest.isSecond
            )
        })

        if (!_.isEmpty(guestWithOutFacilities)) {
            this.props.setError('Есть гости у которых нет услуг.')
            return;
        }

        const guestsCount = guests.filter(guest => {
            let match = guest.phone.match(/\d/g);
            return !_.isEmpty(match) && match.length >= 10;
        }).length

        const mainGuest = guests.find(guest => guest.isMain);

        if (mainGuest) {
            this.props.history.push('/timings')
        } else if (guestsCount < 1) {
            this.props.setError('Нет гостей')
        } else {
            this.props.setMainGuest(_.first(guests).key)
            this.props.history.push('/timings')
        }
    }

    handleGoBack = () => {
        this.props.setParlour(null)
        this.props.push('/parlours')
    }

    render() {
        const {guests, maxGuestCount} = this.props;
        return (
            <Container style={{height: "100%"}} maxWidth="sm" disableGutters={true}>
                <Header allowedGoBack goBackFunction={this.handleGoBack}/>
                {guests ? guests.map(guest =>
                    <GuestCard
                        key={guest.key}
                        guest={guest}
                    />
                ) : null}
                <Box
                    style={{
                        padding: '10px 10px 10px 10px',
                        backgroundColor: "white",
                    }}>
                    <Grid container spacing={2}>
                        {maxGuestCount > guests.length ?
                            <Grid item xs={12}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    startIcon={<PersonAddIcon/>}
                                    onClick={this.handleAddGuest}
                                    fullWidth={true}
                                    disabled={this.props.isLoading}
                                >
                                    Добавить гостя
                                </Button>
                            </Grid>
                            : null}
                    </Grid>
                </Box>
                <AcceptButton
                    variant={"contained"}
                    color="primary"
                    fullWidth
                    endIcon={<NavigateNextIcon/>}
                    onClick={this.handleAccept}
                    disabled={this.props.isLoading}
                >
                    Выбрать время
                </AcceptButton>
            </Container>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Guests));
