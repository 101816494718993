import React from 'react';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import LinearProgress from "@material-ui/core/LinearProgress";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {goBack} from "connected-react-router";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Grid from "@material-ui/core/Grid";

const mapStateToProps = state => ({
    isLoading: state.rest.isLoading,
});

const mapDispatchToProps = {
    goBack: goBack,
};

class Header extends React.Component {
    render() {
        return (
            <AppBar position={"static"}>
                {this.props.isLoading ? <LinearProgress style={{marginBottom: -4}} color="secondary"/> : null}
                <Toolbar variant={"dense"}>
                    <Grid container alignItems={"center"}>
                        <Grid item xs>
                            <img src={'/logo.png'} alt="ТвойТай" height={30}/>
                        </Grid>
                        {this.props.allowedGoBack && !this.props.goBackFunction ? <Grid item>
                            <IconButton color="inherit" onClick={this.props.goBack}>
                                <ArrowBackIcon />
                            </IconButton>
                        </Grid> : null}
                        {this.props.allowedGoBack && this.props.goBackFunction? <Grid item>
                            <IconButton color="inherit" onClick={this.props.goBackFunction}>
                                <ArrowBackIcon />
                            </IconButton>
                        </Grid> : null}
                    </Grid>
                </Toolbar>
            </AppBar>
        )
    }
}

Header.propTypes = {
    allowedGoBack: PropTypes.bool,
    goBackFunction: PropTypes.func,
}

Header.defaultProps = {
    allowedGoBack: false
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Header));