import React from 'react';
import Container from "@material-ui/core/Container";
import Header from "../components/Header";
import {connect} from "react-redux";
import sessionActions from '../actions/session'
import restActions from '../actions/rest'
import {withTranslation} from "react-i18next";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {push} from "connected-react-router";

const mapStateToProps = state => ({
    region: state.session.region,
    regions: state.rest.regions,
});
const mapDispatchToProps = {
    setRegionById: sessionActions.setRegionById,
    fetchRegions: restActions.fetchRegions,
    push: push
};

class SelectRegion extends React.Component {

    componentDidMount() {
        this.props.fetchRegions()
    }

    handleSelectRegion  = (event) => {
        this.props.setRegionById(event.currentTarget.dataset.regionId, true)
    }

    render() {
        const {regions} = this.props;

        return (
            <Container maxWidth="sm" disableGutters>
                <Header/>
                <List component="nav">
                    {
                        regions ? regions.map(region =>
                            <ListItem key={region.id} button>
                                <ListItemText primary={region.name} onClick={this.handleSelectRegion} data-region-id={region.id}/>
                            </ListItem>
                        ) : null
                    }
                </List>
            </Container>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SelectRegion));
