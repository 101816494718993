import React from 'react';
import Container from "@material-ui/core/Container";
import Header from "../components/Header";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import errorActions from '../actions/error';
import Grid from "@material-ui/core/Grid";
import authActions from "../actions/auth";
import {push} from 'connected-react-router';
import Paper from "@material-ui/core/Paper";
import {Avatar, Card, Typography} from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import guestsActions from "../actions/guests";

const mapStateToProps = state => ({
    error: state.error.message,
    auth: state.auth,
});
const mapDispatchToProps = {
    setError: errorActions.setError,
    hideError: errorActions.hideError,
    resetAuth: authActions.reset,
    resetGuests: guestsActions.reset,
    push: push,
};

class SelectAuth extends React.Component {

    state = {

    }

    handleSelectAuth = (event) => {
        if(event.currentTarget.dataset.value === 'reset') {
            this.props.resetAuth()
            this.props.resetGuests()
        }
        this.props.push('/regions')
    }


    render() {
        const {auth} = this.props
        return (
            <Container maxWidth="sm" disableGutters>
                <Header/>
                <Paper variant="outlined" square style={{padding: 20}}>
                    <Typography variant={"h6"} align={"center"}>
                        Продолжить как:
                    </Typography>
                    <Grid container spacing={2} alignItems={"flex-end"}>
                        <Grid item xs={12} sm={12}>
                            <Card data-value="continue" onClick={this.handleSelectAuth}>
                                <CardHeader
                                    avatar={
                                        <Avatar aria-label="recipe" style={{backgroundColor:'#750e7d'}}>
                                            {auth.guest.name.charAt(0)}
                                        </Avatar>
                                    }
                                    title={auth.guest.name}
                                    subheader={auth.guest.phone}
                                    action={
                                        <IconButton>
                                            <NavigateNextIcon/>
                                        </IconButton>
                                    }
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Card data-value="reset" onClick={this.handleSelectAuth}>
                                <CardHeader
                                    avatar={
                                        <Avatar aria-label="recipe">
                                            +
                                        </Avatar>
                                    }
                                    title="Новый гость"
                                    action={
                                        <IconButton>
                                            <NavigateNextIcon/>
                                        </IconButton>
                                    }
                                />
                            </Card>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SelectAuth));