import _ from 'lodash'
import sessionActions from "./session";
import guestsActions from "./guests";

export const facilitiesActionTypes = {
    SET_FACILITIES: '[facilities] set facilities',
    CHANGE_SELECTED: '[facilities] change selected',
    CHANGE_FACILITY: '[facilities] set duration',
    FILTER: '[facilities] filter',
    REMOVE_GUEST_FACILITIES: '[facilities] remove guest facilities',
    RESET: '[facilities] reset'
};

const setFacilities = (guestKey, facilities, priceId) => ({
    type: facilitiesActionTypes.SET_FACILITIES,
    guestKey,
    facilities,
    priceId,
})

const changeFacility = (guestKey, facility) => ({
    type: facilitiesActionTypes.CHANGE_FACILITY,
    guestKey,
    facility,
})

const changeSelected = (guestKey, id) => ({
    type: facilitiesActionTypes.CHANGE_SELECTED,
    guestKey,
    id,
})

const filter = (guestKey, maxDuration, guestDuration) => ({
    type: facilitiesActionTypes.FILTER,
    guestKey,
    maxDuration,
    guestDuration,
})

const reset = () => ({
    type: facilitiesActionTypes.RESET,
})

const removeGuestFacilities = guestKey => ({
    type: facilitiesActionTypes.REMOVE_GUEST_FACILITIES,
    guestKey
})

const changeDuration = (guestKey, id, time) => (dispatch, getState) => {
    const state = getState()
    const facility = state.facilities[guestKey].find(f => f.id === id)
    const duration = facility.durations.find(d => d.time === Number(time))

    dispatch(changeFacility(guestKey, {
        ...facility,
        duration: duration.time,
        price: duration.widgetPrice,
        priceId: duration.priceId,
        facilityPriceId: duration.id,
        priceCosmetic: duration.priceCosmetic,
    }))

    if(facility.selected) dispatch(guestsActions.calcDuration())
}

const _setFacilities = (guestKey = null) => (dispatch, getState) => new Promise((resolve) => {
    const state = getState()
    if(guestKey) {
        dispatch(setFacilities(guestKey, state.rest.facilities, state.session.parlour.price))
    } else {
        state.guests.forEach(guest => {
            dispatch(setFacilities(guest.key, state.rest.facilities, state.session.parlour.price))
        })
    }
    resolve()
})

const _changeSelected = (guestKey, id) => (dispatch) => {
    guestKey = parseInt(guestKey)
    dispatch(changeSelected(guestKey, id))
    dispatch(guestsActions.calcDuration())
    dispatch(_filter(guestKey))
}

const _filter = (guestKey) => (dispatch, getState) => {
    const state = getState()
    const guest = state.guests.find(g => g.key === parseInt(guestKey))
    dispatch(filter(guest.key, state.session.maxDuration, guest.duration))
}

const selectTiming = (timingTimeStart) => (dispatch, getState) => {
    const state = getState()
    const timing = state.rest.timings.find(t => t.timeStart === timingTimeStart)
    const allowedMasters = _.cloneDeep(timing.masters);

    dispatch(sessionActions.setStart(timing.timeStart))

    for (const [guestKey, facilities] of Object.entries(state.facilities)) {
        const selectedFacilities = facilities.filter(f => f.selected)

        if(_.isEmpty(selectedFacilities)) continue;

        const firstSelectedFacility = _.first(selectedFacilities)

        const masterSpecializations = _.union(selectedFacilities.reduce((accum, facility) => {
            if(facility.selected) {
                return [...accum, ...facility.masterSpecializations]
            }
            return [...accum]
        }, []))

        const allowedMastersBySpecializations = allowedMasters.reduce((accum, master) => {
            if(
                master.specializations.some(s => masterSpecializations.includes(s))
            ) {
                return [...accum, master]
            }
            return [...accum]
        }, [])

        const masters = allowedMastersBySpecializations.slice(0, firstSelectedFacility.mastersCount)
        const mastersIds = masters.map(m => m.id)
        _.remove(allowedMasters, am => mastersIds.includes(am.id))

        selectedFacilities.forEach(facility => {
            if(facility.selected) {
                return dispatch(changeFacility(guestKey, {...facility, masters: masters}))
            }
        })
    }
}

const setSecondGuest = (guestKey, secondGuestKey, id) => (dispatch, getState) => {
    const state = getState()
    secondGuestKey = parseInt(secondGuestKey)

    dispatch(guestsActions.setGuestProperty(secondGuestKey, 'isSecond', true))
    dispatch(changeFacility(guestKey, {
        ...state.facilities[guestKey].find(f => f.id === id),
        secondGuest: secondGuestKey
    }))

    if(_.isEmpty(state.facilities[secondGuestKey])) {
        dispatch(_setFacilities(secondGuestKey))
    }

    dispatch(_changeSelected(secondGuestKey, id))
}

export const facilitiesActions = {
    setFacilities: _setFacilities,
    changeSelected: _changeSelected,
    changeDuration,
    selectTiming,
    removeGuestFacilities,
    reset,
    setSecondGuest,
}

export default facilitiesActionTypes;
