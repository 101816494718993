import React from 'react';
import errorActions from "../actions/error";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";

const mapStateToProps = state => ({
    error: state.error.message,
    isOpen: state.error.isOpen
});
const mapDispatchToProps = {
    setError: errorActions.setError,
    hideError: errorActions.hideError,
};

class TabPanel extends React.PureComponent{
   render() {
       return (
           <div
               role="tabpanel"
               hidden={this.props.tabIndex !== this.props.currentTabIndex}
           >
               {this.props.children}
           </div>
       );
   }
}

TabPanel.propTypes = {
    currentTabIndex: PropTypes.number,
    tabIndex: PropTypes.number
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TabPanel));