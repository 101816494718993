import {restActionTypes} from '../actions/rest'

const initialState = {
    isLoading: false,
    regions: [],
    parlours: [],
    facilities: [],
    notAvailableDays: [],
    timings: [],
    visit: {},
    paymentTypes: [],
    payDocs: [],
    discounts: [],
    specializations: [],
    masters: [],
    unclosedVisitsCheck: {
        on_date: null,
        on_time: null
    }
};

export default function rest(state = initialState, action) {
    switch (action.type) {
        case restActionTypes.SET_LOADING:
            return {
                ...state,
                isLoading: action.isLoading,
            };
        case restActionTypes.SET_REGIONS:
            return {
                ...state,
                regions: action.regions,
            };
        case restActionTypes.SET_PARLOURS:
            return {
                ...state,
                parlours: action.parlours,
            };
        case restActionTypes.SET_FACILITIES:
            return {
                ...state,
                facilities: action.facilities,
            };
        case restActionTypes.SET_NOT_AVAILABLE_DAYS:
            return {
                ...state,
                notAvailableDays: action.notAvailableDays,
            };
        case restActionTypes.SET_TIMINGS:
            return {
                ...state,
                timings: action.timings,
            };
        case restActionTypes.SET_VISITS:
            return {
                ...state,
                visits: action.visits
            }
        case restActionTypes.SET_PAYMENT_TYPES:
            return {
                ...state,
                paymentTypes: action.paymentTypes
            }
        case restActionTypes.SET_DISCOUNTS:
            return {
                ...state,
                discounts: action.discounts
            }
        case restActionTypes.SET_PAY_DOCS:
            return {
                ...state,
                payDocs: action.payDocs
            }
        case restActionTypes.SET_SPECIALIZATIONS:
            return {
                ...state,
                specializations: action.specializations
            }
        case restActionTypes.SET_MASTERS:
            return {
                ...state,
                masters: action.masters
            }
        case restActionTypes.SET_UNCLOSED_VISITS_CHECK:
            return {
                ...state,
                unclosedVisitsCheck: action.unclosedVisitsCheck
            }
        case restActionTypes.RESET:
            return initialState
        default:
            return state;
    }
}
