import React from 'react';
import Container from "@material-ui/core/Container";
import Header from "../components/Header";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import errorActions from '../actions/error';
import _ from 'lodash'
import DoneIcon from "@material-ui/icons/Done";
import {push} from 'connected-react-router';
import FacilityCard from "../components/FacilityCard";
import {facilitiesActions} from "../actions/facilities";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../components/TabPanel";
import {getFacilities, getMassageFacilities, getPairedFacilities, getProgramFacilities} from "../selectros/facilities";
import AcceptButton from "../components/AcceptButton";

const mapStateToProps = (state, props) => ({
    facilities: getFacilities(state, {guest:{key: parseInt(props.match.params.id)}}),
    pairedFacilities: getPairedFacilities(state, {guest:{key: parseInt(props.match.params.id)}}),
    massageFacilities: getMassageFacilities(state, {guest:{key: parseInt(props.match.params.id)}}),
    programFacilities: getProgramFacilities(state, {guest:{key: parseInt(props.match.params.id)}}),
    parlour: state.session.parlour,
    guests: state.guests,
    guest: state.guests.find(guest => guest.key === parseInt(props.match.params.id)),
    error: state.error.message,
});

const mapDispatchToProps = {
    setFacilities: facilitiesActions.setFacilities,
    setError: errorActions.error,
    hideError: errorActions.hideError,
    push: push,
    changeSelected: facilitiesActions.changeSelected,
};

class SelectFacility extends React.Component {

    state = {
        selectGuestDialogIsOpen: false,
        currentTabIndex: 1,
    }

    componentDidMount() {
        if (_.isEmpty(this.props.facilities)) {
            this.props.setFacilities(this.props.guest.key)
        }
    }

    handleAccept = () => {
        this.props.push('/guests')
    }

    handleChangeTab = (event, newValue) => {
        this.setState({currentTabIndex: newValue});
    };

    render() {
        const {massageFacilities, programFacilities, pairedFacilities, guest} = this.props
        const {currentTabIndex} = this.state

        return (
            <Container maxWidth="sm" disableGutters style={{position: "relative"}}>
                <Header allowedGoBack/>
                <Paper square>
                    <Tabs
                        value={currentTabIndex}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        onChange={this.handleChangeTab}
                    >
                        {massageFacilities.length > 0 ?
                            <Tab
                                label="Массажи"
                                key={1}
                                value={1}
                            />
                        : null}

                        {programFacilities.length > 0 ?
                            <Tab
                                label="Спа-программы"
                                key={2}
                                value={2}
                            />
                        : null}
                        {pairedFacilities.length > 0 ?
                            <Tab
                                label="Парные программы"
                                key={3}
                                value={3}
                            />
                        : null}
                    </Tabs>
                </Paper>

                <TabPanel key={1} tabIndex={1}
                          currentTabIndex={currentTabIndex}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item sm={12}>
                            {!_.isEmpty(massageFacilities) ? massageFacilities.map((facility) =>
                                <FacilityCard
                                    key={facility.id}
                                    facility={facility}
                                    guest={guest}
                                />
                            ) : null}
                        </Grid>
                    </Grid>
                </TabPanel>

                <TabPanel key={2} tabIndex={2}
                          currentTabIndex={currentTabIndex}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item sm={12}>
                            {!_.isEmpty(programFacilities) ? programFacilities.map((facility) =>
                                <FacilityCard
                                    key={facility.id}
                                    facility={facility}
                                    guest={guest}
                                />
                            ) : null}
                        </Grid>
                    </Grid>
                </TabPanel>

                <TabPanel key={3} tabIndex={3}
                          currentTabIndex={currentTabIndex}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item sm={12}>
                            {!_.isEmpty(pairedFacilities) ? pairedFacilities.map((facility) =>
                                    <FacilityCard
                                        key={facility.id}
                                        facility={facility}
                                        guest={guest}
                                    />
                                ) : null}
                        </Grid>
                    </Grid>
                </TabPanel>

                <AcceptButton
                    variant={"contained"}
                    color="primary"
                    fullWidth
                    endIcon={<DoneIcon/>}
                    onClick={this.handleAccept}
                >
                    Продолжить
                </AcceptButton>
            </Container>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SelectFacility));