import moment from "moment";

export const defaultCurrency = 'RUB';

export const emptyMoney = { val: null, cur: null };

export function formatMoney(money) {
    return (money === undefined || money === null || money.val === null) ? '' : parseFloat(money.val).toLocaleString('ru',{ style: "currency", currency: money.cur ? money.cur : defaultCurrency});
}

export function sumMoney(arg1, arg2) {
    return {
        val: parseFloat(arg1.val) + parseFloat(arg2.val),
        cur: arg1.cur
    }
}

export function formatMinutes(duration) {
    const intPath = Math.trunc(duration)
    const doublePath = duration - intPath
    let hoursString = intPath ? moment.duration(intPath, "hours").locale('ru').humanize({h:24, m: 60}) : ''
    if(intPath === 1) {
        hoursString = `1 ${hoursString}`
    }
    const minutesString = doublePath ? moment.duration(doublePath*60, 'minutes').locale('ru').humanize({h:24, m: 60}) : ''
    return `${hoursString} ${minutesString}`
}

export function parseQueryString() {
    return window.location.search.replace('?', '')
        .split('&')
        .reduce(
            (result, param) => {
                let operand = param.split('=');
                if (operand[0]) {
                    result[decodeURIComponent(operand[0])] = decodeURIComponent(operand[1]);
                }
                return result;
            },
            {}
        );
}