import React from 'react';
import Container from "@material-ui/core/Container";
import Header from "../components/Header";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import {formatMoney} from "../services/utils";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import moment from "moment";
import {push} from "connected-react-router";
import Button from "@material-ui/core/Button";
import sessionActions from "../actions/session";
import _ from 'lodash';
import Paper from "@material-ui/core/Paper";
import ym from 'react-yandex-metrika';

const mapStateToProps = state => ({
    facilities: state.facilities,
    guests: state.guests,
    session: state.session,
    payment: state.payment,
});
const mapDispatchToProps = {
    push: push,
    reset: sessionActions.reset
};

class Success extends React.Component {

    state = {
        tableRows: [],
    }

    componentDidMount() {
        const {guests, facilities} = this.props

        let rows = [];
        guests.forEach(guest => {
            facilities[guest.key].forEach(facility => {
                if (facility.selected) {
                    rows.push({
                        guest: guest.name,
                        facility: facility.name,
                        duration: `${facility.duration * 60} минут`,
                        price: formatMoney(facility.price)
                    })
                }
            })
        })

        this.setState({
            tableRows: rows,
        })
    }

    handleReset = () => {
        ym(41482809,'reachGoal','online')
        ym(41482809,'reachGoal','FORM_SERVICE')
        this.props.reset()
    }

    render() {
        const {tableRows} = this.state
        const {session, payment} = this.props

        return (
            <Container maxWidth="sm" disableGutters>
                <Header/>
                <Paper variant="outlined" square style={{padding: 20}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <List dense>
                                <ListItem>
                                    <ListItemText
                                        primary={<Grid container justify={'space-between'}>
                                            <Grid item xs={6}>
                                                <Typography>Адрес:</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography align={"right"}>{session.parlour.address}</Typography>
                                            </Grid>
                                        </Grid>}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={<Grid container justify={'space-between'}>
                                            <Grid item xs={6}>
                                                <Typography>Время начала:</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    align={"right"}>{moment(session.start).format('DD MMMM HH:mm')}</Typography>
                                            </Grid>
                                        </Grid>}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={<Grid container justify={'space-between'}>
                                            <Grid item xs={6}>
                                                <Typography>Тип оплаты:</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography align={"right"}>{payment.paymentType.text}</Typography>
                                            </Grid>
                                        </Grid>}
                                    />
                                </ListItem>
                                {!_.isEmpty(payment.discount) ?
                                    <ListItem>
                                        <ListItemText
                                            primary={<Grid container justify={'space-between'}>
                                                <Grid item xs={6}>
                                                    <Typography>Скидка:</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography align={"right"}>{payment.discount.name}</Typography>
                                                </Grid>
                                            </Grid>}
                                        />
                                    </ListItem>
                                    : null}

                            </List>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        {tableRows.map((row, key) => (
                                            <TableRow key={key}>
                                                <TableCell align="left">{row.guest}</TableCell>
                                                <TableCell align="left">{row.facility}</TableCell>
                                                <TableCell align="left">{row.duration}</TableCell>
                                                {!session.psh ? <TableCell align="right">{row.price}</TableCell> : null}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        {!session.psh ? <React.Fragment>
                            <Grid item xs={6}>
                                <Typography>Итог</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography align={"right"}>{formatMoney(payment.total)}</Typography>
                            </Grid>
                        </React.Fragment> : null}

                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.handleReset}
                                fullWidth={true}
                                disabled={!payment.paymentType}
                            >
                                Завершить
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Success));
