import {errorActionTypes} from "../actions/error";

const initialState = {
    message: "",
    code: 0,
    isOpen: false
}

export default function error(state = initialState, action) {
    switch (action.type) {
        case errorActionTypes.SET_ERROR_MESSAGE:
            return {
                ...state,
                message: action.errorMessage,
            };
        case errorActionTypes.HIDE_ERROR:
            return {
                ...state,
                isOpen: false,
            };
        case errorActionTypes.SHOW_ERROR:
            return {
                ...state,
                isOpen: true,
            };
        case errorActionTypes.CLEAR_ERROR:
            return {
                ...state,
            };
        default:
            return state;
    }
}

