import {sendSmsCode, checkSmsCode, getGuest} from '../services/rest'
import errorActions from "./error";
import guestsActions from "./guests";
import restActions from "./rest";

export const authActionTypes = {
    SET_SENT: '[auth] sms sent',
    SET_GUEST: '[auth] set guest',
    SET_VERIFIED: '[auth] set code verified',
    RESET: '[auth] rest'
}

const setSent = (isSent) => ({
    type: authActionTypes.SET_SENT,
    isSent
})

const setCodeVerified = (isVerified) => ({
    type: authActionTypes.SET_VERIFIED,
    isVerified
})

const setGuest = (guest) => ({
    type: authActionTypes.SET_GUEST,
    guest
})

const reset = () => ({
    type: authActionTypes.RESET,
})

const sendCode = () => (dispatch, getState) => {
    const state = getState()
    sendSmsCode(state.guests.find(guest => guest.isMain))
        .then(response => {
            if(response.success && response.data.result) {
                dispatch(setSent(true))
                return Promise.resolve()
            }

            dispatch(setSent(false))
            dispatch(errorActions.error(response.error ? response.error.message : response.message))
            return Promise.reject()
        })
}

const checkCode = (phoneCode) => (dispatch, getState) => {
    const state = getState()
    return checkSmsCode({
        ...state.guests.find(guest => guest.isMain),
        phoneCode
    })
        .then(response => {
            if(response.success) {
                dispatch(setCodeVerified(true))
                dispatch(setGuest(response.data))
                dispatch(guestsActions.setGuestProperty(state.guests.find(g => g.isMain).key, 'id', response.data.id))
                return Promise.resolve()
            }

            dispatch(setCodeVerified(false))
            dispatch(errorActions.error(response.error ? response.error.message : response.message))
            return Promise.reject()
        })
}

const fetchGuest = (guestId) => (dispatch) => {
    dispatch(restActions.setLoading(true))

    return getGuest(guestId)
        .then(response => {
            if (response.success) {
                dispatch(setGuest(response.data))
                dispatch(restActions.setLoading(false))
                return Promise.resolve(response.data)
            }
            dispatch(errorActions.error(response.error ? response.error.message : response.message))
            dispatch(restActions.setLoading(false))
            return Promise.reject()
        })
}

const authActions = {
    sendCode,
    checkCode,
    fetchGuest,
    reset,
}

export default authActions