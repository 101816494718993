import React from 'react';
import Container from "@material-ui/core/Container";
import Header from "../components/Header";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import restActions from "../actions/rest";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {IMaskInput} from 'react-imask';
import SendIcon from "@material-ui/icons/Send";
import PayDocCard from "../components/PayDocCard";
import {push} from "connected-react-router";
import Paper from "@material-ui/core/Paper";

const mapStateToProps = state => ({
    payDocs: state.rest.payDocs
});
const mapDispatchToProps = {
    getPayDocs: restActions.fetchPayDocs,
    activatePayDoc: restActions.activatePayDoc,
    payVisit: restActions.payVisit,
    push: push,
};

function MaskedInput ({onChange, name,  ...props}) {
    return <IMaskInput
        {...props}
        mask="aa000000{#}*****"
        unmask={true} // true|false|'typed'
        // DO NOT USE onChange TO HANDLE CHANGES!
        // USE onAccept INSTEAD
        prepare={str => str.toUpperCase()}
        onAccept={
            (value, mask) => onChange({target: { value, name }})
        }
        placeholder='AB000001#12345'
    />;
}

class PayDocs extends React.Component {

    state = {
        payDocString: "",
    }

    componentDidMount() {
        this.props.getPayDocs()
    }

    handleSelectPayDoc = () => {
        this.props.payVisit()
    }

    handleActivatePayDoc = () => {
        this.props.activatePayDoc(this.state.payDocString)
        this.setState({
            payDocString: ""
        })
    }

    handleChangePayDocString = (event) => {
        this.setState({
            payDocString: event.target.value
        })
    }

    render() {
        const {payDocs} = this.props
        const {payDocString} = this.state
        return (
            <Container maxWidth="sm"  disableGutters>
                <Header allowedGoBack/>
                <Paper variant="outlined" square style={{padding: 20}}>
                    <Grid container spacing={2} style={{paddingTop: 20}}>
                        <Grid item xs={12}>
                            <Typography variant={"h6"}>
                                Ваши сертификаты
                            </Typography>
                        </Grid>
                        {payDocs.map(payDoc =>
                            <Grid item xs={6} md={4} key={payDoc.id}>
                                <PayDocCard payDoc={payDoc} handleSelect={this.handleSelectPayDoc}/>
                            </Grid>
                        )}
                    </Grid>

                    <Grid container spacing={2} alignItems={"flex-end"}>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextField
                                name={'discount'}
                                label={'Новый сертификат'}
                                value={payDocString}
                                onChange={this.handleChangePayDocString}
                                fullWidth={true}
                                defaultValue={null}
                                InputProps={{
                                    inputComponent: MaskedInput
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<SendIcon/>}
                                onClick={this.handleActivatePayDoc}
                                fullWidth={true}
                            >
                                Активировать
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PayDocs));
