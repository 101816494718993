import React from 'react';
import Container from "@material-ui/core/Container";
import Header from "../components/Header";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import {Box, Button, Typography} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import AcceptButton from "../components/AcceptButton";
import Paper from "@material-ui/core/Paper";
import {Today} from "@material-ui/icons";

const mapStateToProps = state => ({
    unclosedVisitsCheck: state.rest.unclosedVisitsCheck
});
const mapDispatchToProps = {
};

class UnclosedVisitsCheck extends React.Component {

    handleCreateEntry = () => {
        this.props.history.push("/checkout")
    }

    handlePickAnotherDate = () => {
        this.props.history.push("/timings")
    }

    render() {
        const { unclosedVisitsCheck } = this.props
        const createEntryLock = unclosedVisitsCheck.on_time

        return (
            <Container maxWidth="sm" disableGutters>
                <Header allowedGoBack/>
                    <Paper variant="outlined" square style={{padding: 20}}>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            style={{padding: 20}}
                            spacing={2}
                        >
                            <Grid item xs={12} md={12}>
                                {
                                    unclosedVisitsCheck.on_time ?
                                        <Typography align={"center"} style={{marginBottom: 20}}>
                                            Гость уже имеет запись на выбранное время
                                        </Typography>:
                                        <Typography align={"center"} style={{marginBottom: 20}}>
                                            У гостя уже есть визит на выбранный день.<br/>
                                            Создать ещё одну запись?
                                        </Typography>
                                }
                                <Box display="flex" justifyContent="center">
                                    <Button
                                        variant={"outlined"}
                                        color="primary"
                                        onClick={this.handlePickAnotherDate}
                                        endIcon={<Today/>}
                                    >
                                        Выбрать другой день
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                    <AcceptButton
                        variant={"contained"}
                        color="primary"
                        fullWidth
                        endIcon={<NavigateNextIcon/>}
                        onClick={this.handleCreateEntry}
                        disabled={createEntryLock}
                    >
                        Создать запись
                    </AcceptButton>
            </Container>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UnclosedVisitsCheck));
