import React from 'react';
import errorActions from "../actions/error";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {facilitiesActions} from "../actions/facilities";
import AddIcon from '@material-ui/icons/Add';
import {Box} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import MuiPhoneNumber from "material-ui-phone-number";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Fab from "@material-ui/core/Fab";
import Paper from "@material-ui/core/Paper";
import sessionAction from "../actions/session";
import guestsActions from "../actions/guests";
import {push} from "connected-react-router";
import getSelectedFacilities from "../selectros/facilities";

const mapStateToProps = (state, props) => ({
    error: state.error.message,
    isOpen: state.error.isOpen,
    facilities: getSelectedFacilities(state, props),
    maxFacilitiesCount: state.session.maxFacilitiesCount,
    isLoading: state.rest.isLoading,
});

const mapDispatchToProps = {
    setGuests: sessionAction.setGuests,
    addGuest: guestsActions.addGuest,
    removeGuest: guestsActions.removeGuest,
    setGuestProperty: guestsActions.setGuestProperty,
    calcDuration: guestsActions.calcDuration,
    setError: errorActions.error,
    hideError: errorActions.hideError,
    push: push,
    setMainGuest: guestsActions.setMainGuest,
    changeSelectedFacility: facilitiesActions.changeSelected
};

class GuestCard extends React.PureComponent {

    handleChangeGuestTextField = event => {
        this.props.setGuestProperty(event.currentTarget.dataset.guestKey, event.target.name, event.target.value)
    }

    handleChangeGuestPhone = (value) => {
        this.props.setGuestProperty(this.props.guest.key, 'phone', value)
    }

    handleSelectFacility = (event) => {
        this.props.push(`/guest/facilities/${parseInt(event.currentTarget.dataset.guestKey)}`)
    }

    handleRemoveFacility = (event) => {
        const facilityId = event.currentTarget.dataset.facilityId
        this.props.changeSelectedFacility(this.props.guest.key, facilityId)
    }

    handleRemoveGuest = (event) => {
        this.props.removeGuest(parseInt(event.currentTarget.dataset.value))
    }

    render() {
        const {guest, maxFacilitiesCount, facilities} = this.props

        return (
            <Paper variant="outlined" square style={{padding: 20}}>
                <Grid container spacing={2} justify={'space-between'} alignItems="flex-end">
                    <Grid item xs={10} sm={10} md={6}>
                        <TextField
                            name={'name'}
                            label={'Имя'}
                            value={guest.name}
                            onChange={this.handleChangeGuestTextField}
                            inputProps={{
                                'data-guest-key': guest.key
                            }}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={2} sm={2} md={6}>
                        <Box display="flex" flexDirection="row" justifyContent="flex-end">
                            <Tooltip title="Удалить гостя">
                                <IconButton
                                    size="small"
                                    color="default"
                                    onClick={this.handleRemoveGuest}
                                    data-value={guest.key}
                                >
                                    <CloseIcon/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <MuiPhoneNumber
                            name="phone"
                            label={'Телефон'}
                            defaultCountry={'ru'}
                            value={guest.phone}
                            onChange={this.handleChangeGuestPhone}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <TextField
                            label={'Email'}
                            name={'email'}
                            value={guest.email}
                            onChange={this.handleChangeGuestTextField}
                            inputProps={{
                                'data-guest-key': guest.key
                            }}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name={'comment'}
                            label={'Комментарий'}
                            value={guest.comment}
                            multiline
                            rowsMax={4}
                            onChange={this.handleChangeGuestTextField}
                            inputProps={{
                                'data-guest-key': guest.key
                            }}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <List>
                            {facilities && facilities.length > 0 ?
                                facilities.map((facility) =>
                                    <ListItem key={facility.id}>
                                        <ListItemText
                                            primary={facility.name}
                                            secondary={`${facility.duration * 60} минут`}
                                        />
                                        <ListItemSecondaryAction>
                                            <Tooltip title="Удалить услугу">
                                                <IconButton
                                                    size="small"
                                                    color="default"
                                                    onClick={this.handleRemoveFacility}
                                                    data-facility-id={facility.id}
                                                >
                                                    <CloseIcon/>
                                                </IconButton>
                                            </Tooltip>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ) : <Typography
                                    align={'center'}
                                    color={'textSecondary'}
                                >
                                    Услуги не выбраны
                                </Typography>}
                        </List>
                    </Grid>
                    {
                        (
                            (facilities && maxFacilitiesCount > facilities.length) ||
                            !facilities
                        ) ?
                            <Grid item xs={12}>
                                <Box display="flex" flexDirection="row" justifyContent="center">
                                    <Tooltip title="Выбрать услуги">
                                        <span>
                                            <Fab color="primary"
                                                 size="small"
                                                 onClick={this.handleSelectFacility}
                                                 data-guest-key={guest.key}
                                                 disabled={this.props.isLoading}
                                            >
                                                <AddIcon/>
                                            </Fab>
                                        </span>
                                    </Tooltip>
                                </Box>
                            </Grid>
                            : null
                    }
                </Grid>
            </Paper>
        );
    }
}

GuestCard.propTypes = {
    guest: PropTypes.object.isRequired,
    facilities: PropTypes.array.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GuestCard));