import React from 'react';
import errorActions from "../actions/error";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {formatMinutes, formatMoney} from "../services/utils";
import {facilitiesActions} from "../actions/facilities";
import GroupIcon from '@material-ui/icons/Group';
import ScheduleIcon from '@material-ui/icons/Schedule';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import {Box} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import SelectGuestDialog from "./SelectGuestDialog";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";
import EditIcon from '@material-ui/icons/Edit';
import _ from 'lodash';

const mapStateToProps = state => ({
    error: state.error.message,
    isOpen: state.error.isOpen,
    guests: state.guests,
    maxDuration: state.session.maxDuration,
});
const mapDispatchToProps = {
    setError: errorActions.setError,
    hideError: errorActions.hideError,
    changeSelected: facilitiesActions.changeSelected,
    changeDuration: facilitiesActions.changeDuration,
    setSecondGuest: facilitiesActions.setSecondGuest,
};

class FacilityCard extends React.PureComponent {

    state = {
        descriptionIsOpen: false,
        selectGuestDialogIsOpen: false,
        secondGuest: {}
    }

    componentDidMount() {
        this.getSecondGuestToState()
    }

    getSecondGuestToState = () => {
        if(this.props.facility.secondGuest) {
            this.setState({
                secondGuest: this.props.guests.find(g => g.key === this.props.facility.secondGuest)
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.facility.secondGuest !== prevProps.facility.secondGuest) {
            this.getSecondGuestToState()
        }
    }

    handleChangeDuration = (event) => {
        this.props.changeDuration(this.props.guest.key, this.props.facility.id, event.currentTarget.value)
    }

    handleChangeSelected = () => {
        if(this.props.facility.guestsCount === 2 && !this.props.facility.selected) {
            this.handleOpenChooseGuest()
        } else {
            if(this.props.facility.guestsCount === 2) {
                this.props.setSecondGuest(this.props.guest.key,  null, this.props.facility.id)
            }
            this.props.changeSelected(this.props.guest.key, this.props.facility.id)
        }
    }

    handleExpand = () => {
        this.setState({
            descriptionIsOpen: !this.state.descriptionIsOpen
        })
    }

    handleOpenChooseGuest = () => {
        this.setState({
            selectGuestDialogIsOpen: true
        })
    }

    handleCloseChooseGuest = () => {
        this.setState({
            selectGuestDialogIsOpen: false
        })
    }

    handleChooseGuest = (secondGuestKey) => {
        this.props.changeSelected(this.props.guest.key, this.props.facility.id)
        this.props.setSecondGuest(this.props.guest.key,  secondGuestKey, this.props.facility.id)
        this.handleCloseChooseGuest()
    }

    render() {

        const {facility, guest, maxDuration} = this.props
        const {descriptionIsOpen, secondGuest, selectGuestDialogIsOpen} = this.state
        const durations = facility.durations.filter(duration => duration.time <= maxDuration)

        return (
            <Card variant={"elevation"} elevation={3} style={{marginBottom: 10, marginTop: 10}}>
                <CardHeader
                    title={<Typography variant="h6">{facility.name}</Typography>}
                    action={<IconButton onClick={this.handleExpand}>
                        <ExpandMoreIcon/>
                    </IconButton>}
                />
                <CardMedia
                    image="/logo.png"
                    title="Image"
                />
                <Collapse in={descriptionIsOpen} timeout="auto" unmountOnExit>
                    <CardContent>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {facility.description}
                        </Typography>
                    </CardContent>
                </Collapse>
                <CardActions style={{paddingLeft: 16, paddingRight: 16}}>
                    <Grid container spacing={2} alignItems={"center"}>
                        <Grid item xs={12}>
                            <Grid container alignItems="center">
                                <Grid item xs={2} md={1}>
                                    <Typography variant="body2" color="textSecondary">
                                        <ScheduleIcon />
                                    </Typography>
                                </Grid>
                                <Grid item xs={10} md={11}>
                                    {durations.length > 1 ? durations.map(duration =>
                                            <Button
                                                key={duration.id}
                                                variant={facility.duration === duration.time ? "contained" : "outlined"}
                                                color="primary"
                                                value={duration.time}
                                                disabled={
                                                    (guest.duration + duration.time > maxDuration && !facility.selected) ||
                                                    (guest.duration - facility.duration + duration.time > maxDuration && facility.selected)
                                                }
                                                onClick={this.handleChangeDuration}
                                                size="small"
                                                style={{marginRight: 10, marginBottom: 10}}
                                            >
                                                {formatMinutes(duration.time)}
                                            </Button>
                                        ): <Typography variant="h6">{formatMinutes(facility.durations[0].time)}</Typography> }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <Grid item xs={2}>
                                    <Typography variant="body2" color="textSecondary">
                                        <LocalOfferIcon />
                                    </Typography>
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant="h6">
                                        {formatMoney(facility.price)} {facility.priceCosmetic.val > 0 ? '+' + formatMoney(facility.priceCosmetic) : null}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {facility.guestsCount === 2 ?
                            <Grid item xs={12} md={6}>
                                <Grid container alignItems="center">
                                    <Grid item xs={2}>
                                        <Typography variant="body2" color="textSecondary">
                                            <Tooltip title="Услуга на двоих" aria-label="add">
                                                <GroupIcon />
                                            </Tooltip>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={10}>
                                        {facility.secondGuest && !_.isEmpty(secondGuest) ? <TextField
                                            label="Второй гость"
                                            disabled
                                            defaultValue={!_.isEmpty(secondGuest) ? secondGuest.name : ''}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={this.handleOpenChooseGuest}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </InputAdornment>,
                                            }}
                                        />
                                        :
                                            <Button
                                                color="primary"
                                                startIcon={<AddIcon />}
                                                onClick={this.handleOpenChooseGuest}
                                            >
                                                Выбрать
                                            </Button>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            : null
                        }
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="flex-end">
                                {facility.selected ?
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={this.handleChangeSelected}
                                        startIcon={<RemoveIcon />}
                                    >
                                        Убрать
                                    </Button>
                                :
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleChangeSelected}
                                        startIcon={<AddIcon />}
                                        disabled={facility.disabled}
                                    >
                                        Выбрать
                                    </Button>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </CardActions>

                <SelectGuestDialog
                    exclude={[guest.key]}
                    isOpen={selectGuestDialogIsOpen}
                    allowAddGuest={true}
                    handleChooseGuest={this.handleChooseGuest}
                    handleClose={this.handleCloseChooseGuest}
                />
            </Card>

        );
    }
}

FacilityCard.propTypes = {
    facility: PropTypes.object.isRequired,
    guest: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FacilityCard));