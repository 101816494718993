import {
    getDatesWithoutMasters,
    getFacilities,
    getParlours,
    getRegions,
    getTimingsVisit,
    createVisit as createVisitRequest,
    updateVisit as updateVisitRequest,
    payVisit as payVisitRequest,
    activatePayDoc as activatePayDocRequest,
    sendBugReport as sendBugReportRequest,
    calculateVisit as calculateVisitRequest,
    getPaymentTypes,
    getDiscounts,
    getGuestPyaDocs,
    getSpecializations,
    getDiscount,
    getMastersWorking,
    callback,
    checkUnclosedVisits
} from "../services/rest";
import errorActions from "./error";
import {defaultCurrency} from "../services/utils";
import paymentActions from "./payment";
import {push} from 'connected-react-router';
import sessionActions from "./session";
import moment from "moment";
import guestsActions from "./guests";

export const restActionTypes = {
    SET_LOADING: '[rest] set loading',
    SET_REGIONS: '[rest] set regions',
    SET_PARLOURS: '[rest] set parlours',
    SET_FACILITIES: '[rest] set facilities',
    SET_NOT_AVAILABLE_DAYS: '[rest] set not available days',
    SET_TIMINGS: '[rest] timings',
    SET_VISITS: '[rest] set visits',
    SET_PAYMENT_TYPES: '[rest] set payment types',
    SET_DISCOUNTS: '[rest] set discounts',
    SET_PAY_DOCS: '[rest] set pay docs',
    SET_SPECIALIZATIONS: '[rest] set specializations',
    SET_MASTERS: '[rest] set working masters',
    SET_UNCLOSED_VISITS_CHECK: '[rest] set unclosing visits check',
    RESET: '[rest] reset',
}

const setLoading = isLoading => ({
    type: restActionTypes.SET_LOADING,
    isLoading,
})

const setRegions = regions => ({
    type: restActionTypes.SET_REGIONS,
    regions,
})

const setParlours = parlours => ({
    type: restActionTypes.SET_PARLOURS,
    parlours,
})

const setFacilities = facilities => ({
    type: restActionTypes.SET_FACILITIES,
    facilities,
})

const setNotAvailableDays = notAvailableDays => ({
    type: restActionTypes.SET_NOT_AVAILABLE_DAYS,
    notAvailableDays,
})

const setTimings = timings => ({
    type: restActionTypes.SET_TIMINGS,
    timings,
})

const setVisits = visits => ({
    type: restActionTypes.SET_VISITS,
    visits
})

const setPaymentTypes = paymentTypes => ({
    type: restActionTypes.SET_PAYMENT_TYPES,
    paymentTypes,
})

const setDiscounts = discounts => ({
    type: restActionTypes.SET_DISCOUNTS,
    discounts,
})

const setPayDocs = payDocs => ({
    type: restActionTypes.SET_PAY_DOCS,
    payDocs,
})

const setSpecializations = specializations => ({
    type: restActionTypes.SET_SPECIALIZATIONS,
    specializations,
})

const setMasters = masters => ({
    type: restActionTypes.SET_MASTERS,
    masters,
})

const reset = () => ({
    type: restActionTypes.RESET,
})

const setUnclosedVisitsCheck = unclosedVisitsCheck => ({
    type: restActionTypes.SET_UNCLOSED_VISITS_CHECK,
    unclosedVisitsCheck,
})

const fetchRegions = () => dispatch => {
    dispatch(setLoading(true))
    return getRegions()
        .then(response => {
            if (response.success) {
                dispatch(setRegions(response.data))
                dispatch(setLoading(false))
                return Promise.resolve(response.data)
            }
            dispatch(errorActions.error(response.error ? response.error.message : response.message))
            dispatch(setLoading(false))
            return Promise.reject()
        })
}

const fetchParlours = (regionId = null) => (dispatch, getState) => {
    dispatch(setLoading(true))

    if(regionId === null) {
        const state = getState()
        regionId = state.session.region.id
    }

    return getParlours({region: regionId, onlineEntry: true})
        .then(response => {
            if (response.success) {
                dispatch(setParlours(response.data))
                dispatch(setLoading(false))
                return Promise.resolve(response.data)
            }

            dispatch(errorActions.error(response.error ? response.error.message : response.message))
            dispatch(setLoading(false))
            return Promise.reject()
        })
}

const fetchFacilities = () => (dispatch, getState) => {
    dispatch(setLoading(true))
    const state = getState()
    return getFacilities({parlour: state.session.parlour.id, exclude_not_count_for_masters: 1, forWidget: 1})
        .then(response => {
            if (response.success) {
                dispatch(setFacilities(response.data))
                dispatch(setLoading(false))
                return Promise.resolve(response.data)
            }

            dispatch(errorActions.error(response.error ? response.error.message : response.message))
            dispatch(setLoading(false))
        })
}

const fetchNotAvailableDays = (startPeriod, endPeriod) => (dispatch, getState) => {
    dispatch(setLoading(true))

    const state = getState()

    getDatesWithoutMasters({startPeriod, endPeriod, parlourId: state.session.parlour.id})
        .then(response => {
            if (response.success) {
                dispatch(setNotAvailableDays(response.data))
                dispatch(setLoading(false))
                return Promise.resolve(response.data)
            }

            dispatch(errorActions.error(response.error ? response.error.message : response.message))
            dispatch(setLoading(false))
            return Promise.reject()
        })
}

const fetchTimings = () => (dispatch, getState) => {
    dispatch(setLoading(true))

    const state = getState();

    const selectedFacilities = [];

    for(const guest of state.guests) {
        for(const facility of state.facilities[guest.key]) {
            if(!facility.selected) continue;
            selectedFacilities.push(
                {
                    ...facility,
                    duration: facility.duration * 60,
                    guestKey: guest.key
                }
            )
        }
    }

    const getTimingsRequestBody = {
        parlourId: state.session.parlour.id,
        date: state.session.date,
        guests: state.guests.map(guest => {
            return {
                ...guest,
                duration: guest.duration*60
            }
        }),
        facilities: selectedFacilities,
    }

    getTimingsVisit(getTimingsRequestBody)
        .then(response => {
            if (response.success) {
                dispatch(setTimings(response.data))
                dispatch(setLoading(false))
                return Promise.resolve(response.data)
            }

            dispatch(errorActions.error(response.error ? response.error.message : response.message))
            dispatch(setLoading(false))
            return Promise.reject()
        })
}

const createVisit = () => (dispatch, getState) => {
    dispatch(setLoading(true))
    const state = getState();

    const selectedFacilities = [];
    let total = 0;

    for(const guest of state.guests) {
        for(const facility of state.facilities[guest.key]) {
            if(!facility.selected) continue;

            total += (facility.price.val+facility.priceCosmetic.val) / facility.guestsCount

            selectedFacilities.push(
                {
                    ...facility,
                    duration: facility.duration * 60,
                    guestKey: guest.key
                }
            )
        }
    }

    const createVisitRequestBody = {
        parlourId: state.session.parlour.id,
        start: state.session.start,
        guests: state.guests,
        facilities: selectedFacilities
    }

    return createVisitRequest(createVisitRequestBody)
        .then(response => {
            if (response.success) {
                dispatch(setVisits(response.data))
                dispatch(paymentActions.setTotal({val: total, cur: defaultCurrency}))
                dispatch(paymentActions.setPrice({val: total, cur: defaultCurrency}))
                dispatch(setLoading(false))
                if (state.auth.guest) {
                    dispatch(guestsActions.setGuestProperty(state.guests.find(guest => guest.isMain).key, 'id', state.auth.guest.id))
                    dispatch(fetchUnclosedVisitsCheck())
                } else {
                    dispatch(push('/registration'))
                }

                return Promise.resolve(response.data)
            }
            dispatch(setLoading(false))
            dispatch(errorActions.error(response.error ? response.error.message : response.message))
            return Promise.reject()
        })
}

const updateVisit = () => (dispatch, getState) => {
    dispatch(setLoading(true))

    const state = getState();

    let selectedFacilities = []
    let visitIds = []

    for(const guest of state.guests) {

        let visit = state.rest.visits.visits.find((visit) => visit.guestKey === guest.key)
        visitIds.push(visit.id)

        for(const facility of state.facilities[guest.key]) {
            if(!facility.selected) continue;


            selectedFacilities.push(
                {
                    ...facility,
                    duration: facility.duration * 60,
                    guestKey: guest.key,
                    visitId: visit.id
                }
            )
        }
    }

    const updateVisitRequestBody = {
        mainVisitId: state.rest.visits.mainVisit,
        visitIds: visitIds,
        guest: {...state.guests.find(g => g.isMain), id: state.auth.guest.id},
        payment: {
            paymentType: state.payment.paymentType.id,
            discountId: state.payment.discount ? state.payment.discount.id : null,
            discountNumber: state.payment.discount ? state.payment.discount.discountNumber : null,
        },
        referralPartnerName: state.session.referralPartnerName,
        start: state.session.start,
        facilities: selectedFacilities
    }

    updateVisitRequest(updateVisitRequestBody)
        .then(response => {
            if (response.success) {
                dispatch(setLoading(false))
                return Promise.resolve(response.data)
            }
            dispatch(setLoading(false))
            dispatch(push('/guests'))
            dispatch(errorActions.error(response.error ? response.error.message : response.message))
            return Promise.reject()
        })
}

const payVisit = () => (dispatch, getState) => {
    dispatch(setLoading(true))
    const state = getState();

    const payVisitRequestBody = {
        mainVisitId: state.rest.visits.mainVisit,
        guest: {
            id: state.auth.guest.id,
        },
        discountId: state.payment.discount ? state.payment.discount.id : null,
        payDocId: state.payment.payDoc ? state.payment.payDoc.id : null,
        sum: state.payment.total
    }

    payVisitRequest(payVisitRequestBody)
        .then(response => {
            if (response.success) {
                dispatch(setLoading(false))
                dispatch(push('/success'))
                return Promise.resolve(response.data)
            }
            dispatch(setLoading(false))
            dispatch(errorActions.error(response.error ? response.error.message : response.message))
            return Promise.reject()
        })
}

const fetchPaymentTypes = () => (dispatch) => {
    dispatch(setLoading(true))
    return getPaymentTypes()
        .then(response => {
            if (response.success) {
                dispatch(setPaymentTypes(response.data))
                return Promise.resolve(response.data)
            }

            dispatch(errorActions.error(response.error ? response.error.message : response.message))
            dispatch(setLoading(false))
        })
}

const fetchDiscounts = () => (dispatch, getState) => {
    dispatch(setLoading(true))
    const state = getState()
    const getDiscountsRequestBody = {
        mainVisitId: state.rest.visits.mainVisit,
        parlourId: state.session.parlour.id,
        guest: {
            id: state.auth.guest.id
        },
        discount: state.get.discount,
        guestsCount: state.guests.length
    }

    return getDiscounts(getDiscountsRequestBody)
        .then(response => {
            if (response.success) {
                dispatch(setDiscounts(response.data))
                if(state.get.discount) {
                    dispatch(paymentActions.changeDiscount(state.get.discount))
                }
                dispatch(setLoading(false))
                return Promise.resolve(response.data)
            }

            dispatch(errorActions.error(response.error ? response.error.message : response.message))
            dispatch(setLoading(false))
        })
}

const fetchPayDocs = () => (dispatch, getState) => {
    dispatch(setLoading(true))
    const state = getState()
    return getGuestPyaDocs(state.auth.guest.id)
        .then(response => {
            if (response.success) {
                dispatch(setPayDocs(response.data))
                dispatch(setLoading(false))
                return Promise.resolve(response.data)
            }

            dispatch(errorActions.error(response.error ? response.error.message : response.message))
            dispatch(setLoading(false))
        })
}

const fetchSpecializations = () => (dispatch) => {
    dispatch(setLoading(true))
    return getSpecializations()
        .then(response => {
            if (response.success) {
                dispatch(setSpecializations(response.data))
                dispatch(setLoading(false))
                return Promise.resolve(response.data)
            }

            dispatch(errorActions.error(response.error ? response.error.message : response.message))
            dispatch(setLoading(false))
        })
}

const activatePayDoc = (payDocString) => (dispatch, getState) => {
    dispatch(setLoading(true))
    const state = getState()

    const payDocActivateRequestBody = {
        payDocString,
        guest: state.auth.guest
    }

    activatePayDocRequest(payDocActivateRequestBody)
        .then(response => {
            if (response.success) {
                dispatch(fetchPayDocs())
                dispatch(setLoading(false))
                return Promise.resolve(response.data)
            }

            dispatch(errorActions.error(response.error ? response.error.message : response.message))
            dispatch(setLoading(false))
        })
}

const fetchDiscount = (discountId) => (dispatch) => {
    dispatch(setLoading(true))

    getDiscount(discountId)
        .then(response => {
            if (response.success) {
                dispatch(sessionActions.setDiscount(response.data))
                dispatch(setLoading(false))
                return Promise.resolve(response.data)
            }
            dispatch(errorActions.error(response.error ? response.error.message : response.message))
            dispatch(setLoading(false))
            return Promise.reject()
        })
}

const fetchWorkingMasters = () => (dispatch, getState) => {
    dispatch(setLoading(true))
    const state = getState()

    getMastersWorking(state.session.parlour, state.session.date, state.session.date)
        .then(response => {
            if (response.success) {
                dispatch(setMasters(response.data))
                dispatch(setLoading(false))
                return Promise.resolve(response.data)
            }
            dispatch(errorActions.error(response.error ? response.error.message : response.message))
            dispatch(setLoading(false))
            return Promise.reject()
        })
}

const sendBugReport = (message) => (dispatch, getState) => {
    dispatch(setLoading(true))
    const state = getState()

    sendBugReportRequest({state, message})
        .then(response => {
            if (response.success) {
                dispatch(setLoading(false))
                return Promise.resolve(response.data)
            }
            dispatch(errorActions.error(response.error ? response.error.message : response.message))
            dispatch(setLoading(false))
            return Promise.reject()
        })
}

const calculateVisit = () => (dispatch, getState) => {
    dispatch(setLoading(true))
    const state = getState()

    calculateVisitRequest({mainVisitId: state.rest.visits.mainVisit})
        .then(response => {
            if (response.success) {
                dispatch(setLoading(false))
                dispatch(paymentActions.setPrice(response.data))
                return Promise.resolve(response.data)
            }
            dispatch(errorActions.error(response.error ? response.error.message : response.message))
            dispatch(setLoading(false))
            return Promise.reject()
        })
}

const sendCallback = () => (dispatch, getState) => {
    dispatch(setLoading(true))
    const state = getState()

    callback({
        parlour: state.session.parlour.name,
        name: state.guests.find(g => g.isMain).name,
        phone: state.guests.find(g => g.isMain).phone,
        date: state.session.date,
        guestsCount: state.guests.length,
    })
        .then(response => {
            if (response.success) {
                dispatch(setLoading(false))
                dispatch(sessionActions.setCallbackSent(response.data))
                return Promise.resolve(response.data)
            }
            dispatch(errorActions.error(response.error ? response.error.message : response.message))
            dispatch(setLoading(false))
            return Promise.reject()
        })
}

const fetchUnclosedVisitsCheck = () => (dispatch, getState) => {
    dispatch(setLoading(true))
    const state = getState()

    const guests = state.guests
    let phones = []
    guests.forEach(guest => {
        if (guest.phone) {
            phones.push(guest.phone)
        }
    })

    const start  = moment(state.session.start).utc().format()
    let maxDuration = 0
    Object.keys(state.facilities).forEach(facilitiesKey => {
        let selected = state.facilities[facilitiesKey].filter( f => f.selected)
        let facilitiesMaxDuration = selected.reduce((d, s) => {d += s.duration; return d}, 0)

        if (facilitiesMaxDuration > maxDuration) {
            maxDuration = facilitiesMaxDuration
        }
    })
    const end = moment(state.session.start).add(maxDuration, 'hours').utc().format()

    checkUnclosedVisits(phones, start, end)
        .then(response => {
            if (response.success) {
                dispatch(setUnclosedVisitsCheck(response.data))
                dispatch(setLoading(false))
                if (response.data.on_date) {
                    dispatch(push('/unclosed-visits-check'))
                } else {
                    dispatch(push('/checkout'))
                }

                return Promise.resolve(response.data)
            }
            dispatch(errorActions.error(response.error ? response.error.message : response.message))
            dispatch(setLoading(false))
            return Promise.reject()
        })
}

export const restActions = {
    fetchRegions,
    fetchParlours,
    fetchFacilities,
    fetchNotAvailableDays,
    fetchTimings,
    createVisit,
    updateVisit,
    payVisit,
    fetchPaymentTypes,
    fetchDiscounts,
    fetchPayDocs,
    activatePayDoc,
    fetchSpecializations,
    fetchDiscount,
    sendBugReport,
    reset,
    fetchWorkingMasters,
    calculateVisit,
    setLoading,
    sendCallback,
    fetchUnclosedVisitsCheck
}

export default restActions;

