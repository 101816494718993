import React from 'react';
import {connect} from "react-redux";
import {Redirect, withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import {Route, Switch} from "react-router-dom";
import SelectRegionPage from "./pages/SelectRegion";
import SelectAuthPage from "./pages/SelectAuth";
import RouteWithVerification from "./components/RouteWithVerification";
import SelectParlourPage from "./pages/SelectParlour";
import CalendarPage from "./pages/Calendar";
import GuestsPage from "./pages/Guests";
import SelectFacility from "./pages/SelectFacility";
import SelectTimingPage from "./pages/SelectTiming";
import _ from 'lodash';
import PhoneCheck from "./pages/PhoneCheck";
import Checkout from "./pages/Checkout";
import PayDocs from "./pages/PayDocs";
import Success from "./pages/Success";
import UnclosedVisitsCheck from "./pages/UnclosedVisitsCheck";
import sessionActions from "./actions/session";
import {Paper} from "@material-ui/core";
import {push} from "connected-react-router";

const mapStateToProps = state => ({
    isLoading: state.rest.isLoading,
    auth: state.auth,
    session: state.session,
});

const mapDispatchToProps = {
    init: sessionActions.init,
    push: push,
};

class App extends React.Component {

    state = {}

    constructor (props) {
        super(props)
        this.props.init()
    }

    render() {

        return (
            <Paper style={{minHeight: '100vh'}}>
                <Switch>
                    <Route path="/" exact={true}>
                        {_.isEmpty(this.props.auth.guest) ? <Redirect push to="/regions" /> : <SelectAuthPage />}
                    </Route>
                    <Route path="/regions" exact={true}>
                        {!_.isEmpty(this.props.session.region) ? <Redirect push to="/parlours"/> : <SelectRegionPage/>}
                    </Route>
                    <RouteWithVerification
                        path="/parlours"
                        exact={true}
                        verification={(state) => {
                            return !!(state.session.region)
                        }}
                    >
                        {!_.isEmpty(this.props.session.parlour) ? <Redirect push to="/guests"/> : <SelectParlourPage/>}
                    </RouteWithVerification>
                    <RouteWithVerification
                        path="/guests"
                        component={GuestsPage}
                        strict={true}
                        exact={true}
                        verification={(state) => {
                            return !!(state.session.region) && !!(state.session.parlour) && !!(state.session.date)
                        }}
                    />
                    <RouteWithVerification
                        path='/guest/facilities/:id'
                        render={(props) => {return (<SelectFacility {...props} />)}}
                        verification={(state) => {
                            return state.guests.length > 0 && !!(state.session.parlour)
                        }}
                    />
                    <RouteWithVerification
                        path="/calendar"
                        component={CalendarPage}
                        strict={true}
                        exact={true}
                        verification={(state) => {
                            return !!(state.session.parlour)
                        }}
                    />
                    <RouteWithVerification
                        path="/timings"
                        component={SelectTimingPage}
                        strict={true}
                        exact={true}
                        verification={(state) => {
                            return !!(state.session.parlour)
                        }}
                    />
                    <RouteWithVerification
                        path="/registration"
                        component={PhoneCheck}
                        strict={true}
                        exact={true}
                        verification={(state) => {
                            return !!(state.session.parlour)
                        }}
                    />
                    <RouteWithVerification
                        path="/checkout"
                        component={Checkout}
                        strict={true}
                        exact={true}
                        verification={(state) => {
                            return !!(state.auth.guest) && !!(state.session.parlour)
                        }}
                    />
                    <RouteWithVerification
                        path="/pay-docs"
                        component={PayDocs}
                        strict={true}
                        exact={true}
                        verification={(state) => {
                            return !!(state.auth.guest) && !!(state.session.parlour)
                        }}
                    />
                    <RouteWithVerification
                        path="/success"
                        component={Success}
                        strict={true}
                        exact={true}
                        verification={(state) => {
                            return !!(state.auth.guest)
                        }}
                    />
                    <RouteWithVerification
                        path="/unclosed-visits-check"
                        component={UnclosedVisitsCheck}
                        strict={true}
                        exact={true}
                        verification={(state) => {
                            return state.guests.length > 0
                                && !!(state.session.start)
                                && state.rest.unclosedVisitsCheck.on_date
                        }}
                    />
                    <Route render={() => <h1>Not Found</h1>}/>
                </Switch>
            </Paper>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(App)));
