import {facilitiesActions} from "./facilities";
import _ from 'lodash'
import {emptyGuest} from "../reducers/guests";
import errorActions from "./error";

export const guestsActionTypes = {
    ADD_GUEST: '[guests] add guest',
    REMOVE_GUEST: '[guests] remove guest',
    SET_DURATION: '[guests] set duration',
    SET_GUEST_PROPERTY: '[guests] set guest property',
    SET_MAIN_GUEST: '[guests] set main guest',
    RESET: '[guests] reset'
};

const addGuest = (guest, setNumber = false) => ({
    type: guestsActionTypes.ADD_GUEST,
    guest,
    setNumber
})

const removeGuest = guestKey => ({
    type: guestsActionTypes.REMOVE_GUEST,
    guestKey,
})

const setDuration = (guestKey, duration) => ({
    type: guestsActionTypes.SET_DURATION,
    guestKey,
    duration,
})

const setGuestProperty = (guestKey, property, value) => ({
    type: guestsActionTypes.SET_GUEST_PROPERTY,
    guestKey: parseInt(guestKey),
    property,
    value
})

const setMainGuest = (guestKey) => ({
    type: guestsActionTypes.SET_MAIN_GUEST,
    guestKey: parseInt(guestKey)
})

const reset = () => ({
    type: guestsActionTypes.RESET
})

const calcDuration = () => (dispatch, getState) => {
    const state = getState()
    _.forEach(state.guests, guest => {
        if(state.facilities[guest.key]) {
            dispatch(setDuration(guest.key, state.facilities[guest.key].reduce((duration, facility) => {
                return facility.selected ? duration + facility.duration : duration
            }, 0)))
        }
    })
}

const _removeGuest = guestKey => (dispatch) => {
    dispatch(removeGuest(guestKey))
    dispatch(facilitiesActions.removeGuestFacilities(guestKey))
}

const _addGuest = (guestData = emptyGuest) => (dispatch, getState) => {
    const state = getState()
    if(state.guests.length >= state.session.maxGuestCount)
        return dispatch(errorActions.error('Привышен лимит гостей'))
    else
        return dispatch(addGuest(guestData))
}

export const guestsActions = {
    addGuest: _addGuest,
    removeGuest: _removeGuest,
    calcDuration,
    setGuestProperty,
    setMainGuest,
    reset,
}

export default guestsActions;
