import i18n from "i18next";
import XHR from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import moment from "moment";

const availableLanguages = ['ru', 'en', 'cs'];

// i18n.on('initialized', options => {});

i18n
    .use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "ru",
        load: "languageOnly",
        //debug: true,
        whitelist: availableLanguages,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        detection: {
            caches: ['localStorage', 'cookie'],
            cookieMinutes: 300,
        }
    });

i18n.on('languageChanged', function(lng) {
    moment.locale(lng)
});

export default i18n;