import baseRequest from "./baseRequest";
import * as queryString from "query-string-object";

const HOST = process.env.REACT_APP_HOST
const API_KEY = process.env.REACT_APP_API_KEY

export function getRegions(search, page = 1, per_page = -1, order = 'asc', order_by = 'name') {
    let url = HOST + '/get-regions';
    const q = queryString.stringify({search, page, per_page: per_page ? per_page : -1, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url, {Authorization: 'ApiKey ' + API_KEY});
}

export function getParlours(search, page = 1, per_page = -1, order = 'asc', order_by = 'name') {
    let url = HOST + '/get-parlours';
    const q = queryString.stringify({search, page, per_page: per_page ? per_page : -1, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url, {Authorization: 'ApiKey ' + API_KEY});
}

export function getDatesWithoutMasters(search, page = 1, per_page = -1, order = 'asc', order_by = 'name') {
    let url = HOST + '/get-dates-without-masters';
    const q = queryString.stringify({search, page, per_page: per_page ? per_page : -1, order, order_by});

    return baseRequest(url + `?${q}`, {Authorization: 'ApiKey ' + API_KEY});
}

export function getFacilities(search, page = 1, per_page = -1, order = 'asc', order_by = 'day') {
    let url = HOST + '/get-facilities';
    const q = queryString.stringify({search, page, per_page: per_page ? per_page : -1, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url, {Authorization: 'ApiKey ' + API_KEY});
}

export function getSpecializations(search, page = 1, per_page = -1, order = 'asc', order_by = 'day') {
    let url = HOST + '/get-specializations';
    const q = queryString.stringify({search, page, per_page: per_page ? per_page : -1, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url, {Authorization: 'ApiKey ' + API_KEY});
}

export function getTimingsVisit(getTimingsRequestBody) {
    let url = HOST + '/get-timing-visit';
    return baseRequest(url, {
        Authorization: 'ApiKey ' + API_KEY,
        method: 'POST',
        body: JSON.stringify(getTimingsRequestBody),
    })
}

export function getGuest(id) {
    let url = HOST + `/guest/${id}`;
    return baseRequest(url, {Authorization: 'ApiKey ' + API_KEY});
}

export function getDiscount(id) {
    let url = HOST + `/discount/${id}`;
    return baseRequest(url, {Authorization: 'ApiKey ' + API_KEY});
}

export function getDiscounts(getDiscountsRequestBody) {
    return baseRequest( HOST + '/discounts' , {
        method: 'POST',
        body: JSON.stringify(getDiscountsRequestBody),
        Authorization: 'ApiKey ' + API_KEY
    });
}

export function sendSmsCode(guest) {
    return baseRequest(HOST + '/send-phone-code', {
        method: 'POST',
        body: JSON.stringify(guest),
        Authorization: 'ApiKey ' + API_KEY
    });
}

export function checkSmsCode(guest) {
    return baseRequest(HOST + '/check-phone-code', {
        method: 'POST',
        body: JSON.stringify(guest),
        Authorization: 'ApiKey ' + API_KEY
    });
}

export function createVisit(createVisitRequestBody) {
    return baseRequest(HOST + '/create-visit', {
        method: 'POST',
        body: JSON.stringify(createVisitRequestBody),
        Authorization: 'ApiKey ' + API_KEY
    });
}

export function updateVisit(updateVisitRequestBody) {
    return baseRequest(HOST + '/update-visit', {
        method: 'POST',
        body: JSON.stringify(updateVisitRequestBody),
        Authorization: 'ApiKey ' + API_KEY
    });
}

export function payVisit(payVisitRequestBody) {
    return baseRequest(HOST + '/pay-visit', {
        method: 'POST',
        body: JSON.stringify(payVisitRequestBody),
        Authorization: 'ApiKey ' + API_KEY
    });
}

export function getPaymentTypes() {
    return baseRequest(HOST + '/payment-types', {Authorization: 'ApiKey ' + API_KEY})
}

export function getGuestPyaDocs(id) {
    let url = HOST + `/get-guest-pay-docs/${id}`;
    return baseRequest(url, {Authorization: 'ApiKey ' + API_KEY});
}

export function activatePayDoc(payDocActivateRequestBody) {
    return baseRequest( HOST + `/activate-pay-doc` , {
        method: 'POST',
        body: JSON.stringify(payDocActivateRequestBody),
        Authorization: 'ApiKey ' + API_KEY
    });
}

export function sendBugReport(bugReportRequestBody) {
    return baseRequest(HOST + '/bug-report', {
        method: 'POST',
        body: JSON.stringify(bugReportRequestBody),
        Authorization: 'ApiKey ' + API_KEY
    });
}

export function getMastersWorking(parlour, fromDate, toDate) {
    return baseRequest(HOST + '/master-working', {
        query: {parlour, fromDate, toDate, include: 'workShift'}
    });
}

export function calculateVisit(calculateVisitRequestBody) {
    return baseRequest(HOST + '/calculate-visit', {
        method: 'POST',
        body: JSON.stringify(calculateVisitRequestBody),
        Authorization: 'ApiKey ' + API_KEY
    });
}

export function callback(callbackVisitRequestBody) {
    return baseRequest(HOST + '/callback', {
        method: 'POST',
        body: JSON.stringify(callbackVisitRequestBody),
        Authorization: 'ApiKey ' + API_KEY
    });
}

export function checkUnclosedVisits(phones, start, end) {
    let url = HOST + `/check-unclosed-visits`;
    return baseRequest(url, {
        query: {
            phones,
            start,
            end
        },
        Authorization: 'ApiKey ' + API_KEY
    });
}