export const getActionTypes = {
    SET_GET_PARAMS: '[get] set get params',
    SET_FACILITY: '[get] set facility',
    RESET: '[get] reset'
};

const setGetParams = getParams => ({
    type: getActionTypes.SET_GET_PARAMS,
    getParams,
})

const setFacility = facilityId => ({
    type: getActionTypes.SET_FACILITY,
    facilityId
})

const reset = () => ({
    type: getActionTypes.RESET
})

export const getActions = {
    setGetParams,
    setFacility,
    reset,
}

export default getActions;
