import * as queryString from "query-string-object";
import i18n from "./i18n";

export default async function baseRequest(url, params = {}, isJson = true) {

    if (!params.headers) {
        params.headers = new Headers();
    }

    params.headers.set('Authorization', params.Authorization);

    if (!params.method) {
        params.method = 'GET';
    }

    if (['PUT', 'POST'].indexOf(params.method) >= 0 && isJson) {
        params.headers.set('Content-Type', 'application/json');

        if (params.body && typeof params.body === 'object') {
            params.body = JSON.stringify(params.body);
        }
    }

    params.query = {
        i18next: i18n.language,
        ...params.query,
    };

    if (params.query) {
        const queryStrIndex = url.indexOf('?');
        let queryObj = {};
        if (queryStrIndex >= 0) {
            queryObj = queryString.parse(url.substring(queryStrIndex+1));
            url = url.substring(0, queryStrIndex);
        }
        queryObj = {...queryObj, ...params.query};
        url = url + '?' + queryString.stringify(queryObj);
    }

    const request = new Request(url, params);


    let response = await fetch(request);


    if (response.headers.get('Content-Type') === 'application/json') {
        return response.json();
    }

    return response;
}
