import moment from 'moment';
import {sessionActionTypes} from "../actions/session";

export const initialState = {
    region: null,
    parlour: null,
    date: new moment().format('YYYY-MM-DD'),
    guests: [],
    start: '',
    referralPartnerName: '',
    discount: {},
    maxDuration: 3,
    maxGuestCount: 10,
    allowedPaymentTypeIds: ['cash', 'cashless', 'paydoc'],
    maxFacilitiesCount: 10,
    psh: false,
    debug: 0,
    callbackSent: false,
}

export default function session(state = initialState, action) {
    switch (action.type) {
        case sessionActionTypes.SET_REGION:
            return {
                ...state,
                region: action.region,
            };

        case sessionActionTypes.SET_PARLOUR:
            return {
                ...state,
                parlour: action.parlour,
            };

        case sessionActionTypes.SET_DATE:
            return {
                ...state,
                date: action.date,
            };

        case sessionActionTypes.SET_START:
            return {
                ...state,
                start: action.start,
            };

        case sessionActionTypes.SET_DISCOUNT:
            return {
                ...state,
                discount: action.discount,
            };

        case sessionActionTypes.SET_REFERRAL_PARTNER_NAME:
            return {
                ...state,
                referralPartnerName: action.referralPartnerName,
            };

        case sessionActionTypes.SET_MAX_GUESTS_COUNT:
            return {
                ...state,
                maxGuestCount: action.maxGuestCount,
            };

        case sessionActionTypes.SET_MAX_FACILITIES_COUNT:
            return {
                ...state,
                maxFacilitiesCount: action.maxFacilitiesCount,
            };

        case sessionActionTypes.SET_MAX_DURATION:
            return {
                ...state,
                maxDuration: action.maxDuration,
            };

        case sessionActionTypes.SET_ALLOWED_PAYMENT_TYPES:
            return {
                ...state,
                allowedPaymentTypeIds: action.paymentTypeIds,
            };

        case sessionActionTypes.RESET:
            return {
                ...initialState,
                region: state.region,
            };

        case sessionActionTypes.SET_PSH:
            return {
                ...state,
                psh: action.psh
            }

        case sessionActionTypes.SET_DEBUG:
            return {
                ...state,
                debug: parseInt(action.debug)
            }

        case sessionActionTypes.SET_CALLBACK_SENT:
            return {
                ...state,
                callbackSent: action.callbackSent
            }

        default:
            return state;
    }
}