import React from 'react';
import errorActions from "../actions/error";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";

const mapStateToProps = state => ({
    error: state.error.message,
    isOpen: state.error.isOpen
});
const mapDispatchToProps = {
    setError: errorActions.setError,
    hideError: errorActions.hideError,
};

class ErrorMessage extends React.PureComponent{
   render() {
       return (
           <Dialog
               open={this.props.isOpen}
               onClose={this.props.hideError}
               aria-labelledby="alert-dialog-title"
               aria-describedby="alert-dialog-description"
           >
               <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
               <DialogContent>
                   <DialogContentText id="alert-dialog-description">
                       {this.props.error}
                   </DialogContentText>
               </DialogContent>
               <DialogActions>
                   <Button onClick={this.props.hideError} color="primary">
                       Close
                   </Button>
               </DialogActions>
           </Dialog>
       );
   }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ErrorMessage));