import guestsActions from "./guests";
import paymentActions from "./payment";
import {facilitiesActions} from "./facilities";
import getActions from "./get";
import {push} from "connected-react-router";
import restActions from "./rest";
import authActions from "./auth";
import {parseQueryString} from "../services/utils";

export const sessionActionTypes = {
    SET_REGION: '[session] set region',
    SET_PARLOUR: '[session] set parlour',
    SET_DATE: '[session] set date',
    SET_START: '[session] set start',
    SET_REFERRAL_PARTNER_NAME: '[session] set referral partner name',
    SET_DISCOUNT: '[session] set discount',
    SET_MAX_GUESTS_COUNT: '[session] set max guests count',
    SET_MAX_DURATION: '[session] set max duration',
    SET_ALLOWED_PAYMENT_TYPES: '[session] set allowed payment types',
    SET_MAX_FACILITIES_COUNT: '[session] set max facilities count',
    RESET: '[session] reset',
    SET_PSH: '[session] set psh',
    SET_DEBUG: '[session] set debug',
    SET_CALLBACK_SENT: '[session] set callback sent'
}

const setDebug = debug => ({
    type: sessionActionTypes.SET_DEBUG,
    debug
})

const setRegion = region => ({
    type: sessionActionTypes.SET_REGION,
    region,
})

const setParlour = parlour => ({
    type: sessionActionTypes.SET_PARLOUR,
    parlour,
})

const setDate = date => ({
    type: sessionActionTypes.SET_DATE,
    date,
})

const setStart = start => ({
    type: sessionActionTypes.SET_START,
    start,
})

const setReferralPartnerName = referralPartnerName => ({
    type: sessionActionTypes.SET_REFERRAL_PARTNER_NAME,
    referralPartnerName,
})

const setDiscount = discount => ({
    type: sessionActionTypes.SET_DISCOUNT,
    discount,
})

const setMaxGuestsCount = maxGuestCount => ({
    type: sessionActionTypes.SET_MAX_GUESTS_COUNT,
    maxGuestCount,
})

const setMaxDuration = maxDuration => ({
    type: sessionActionTypes.SET_MAX_DURATION,
    maxDuration,
})

const setAllowedPaymentTypes = paymentTypeIds => ({
    type: sessionActionTypes.SET_ALLOWED_PAYMENT_TYPES,
    paymentTypeIds,
})

const setMaxFacilitiesCount = maxFacilitiesCount => ({
    type: sessionActionTypes.SET_MAX_FACILITIES_COUNT,
    maxFacilitiesCount,
})

const reset = () => ({
    type: sessionActionTypes.RESET,
})

const setPsh = psh => ({
    type: sessionActionTypes.SET_PSH,
    psh,
})

const setCallbackSent = callbackSent => ({
    type: sessionActionTypes.SET_CALLBACK_SENT,
    callbackSent,
})


const _setDiscount = (discount) => (dispatch) => {
    dispatch(setDiscount(discount))
    if (
        discount.handler === 'Rounded5Perekrestok' ||
        discount.handler === 'AmountAsYouWishPerekrestok' ||
        discount.handler === 'AmountAsYouWish'
    ) {
        dispatch(setMaxGuestsCount(1))
        dispatch(setMaxFacilitiesCount(1))
        dispatch(setMaxDuration(0.5))
        dispatch(sessionActions.setAllowedPaymentTypes(['cash']))
        dispatch(paymentActions.changePaymentType('cash'))
    }

    if(
        discount.handler === 'AmountAsYouWishPerekrestok' ||
        discount.handler === 'AmountAsYouWish'
    ) {
        dispatch(setPsh(true))
    }
}

const setRegionById = (id) => (dispatch, getState) => new Promise(
    (resolve) => {
        const state = getState()
        dispatch(sessionActions.setRegion(state.rest.regions.find(region => region.id === id)))
        resolve()
    }
);

const setParlourById = (id) => (dispatch, getState) => new Promise(
    (resolve) => {
        const state = getState()
        dispatch(sessionActions.setParlour(state.rest.parlours.find(parlour => parlour.id === id)))
        dispatch(restActions.fetchFacilities())
        dispatch(facilitiesActions.reset())
        resolve()
    }
);

const _reset = () => (dispatch) => {
    dispatch(reset())
    dispatch(getActions.reset())
    dispatch(facilitiesActions.reset())
    dispatch(paymentActions.reset())
    dispatch(guestsActions.reset())
    dispatch(push('/parlours'))
}

const init =  () => (dispatch, getState) => {
    const getParams = parseQueryString()

    dispatch(getActions.setGetParams(getParams))

    dispatch(setDebug(process.env.REACT_APP_DEBUG))

    dispatch(restActions.fetchPaymentTypes())
        .then((data) => dispatch(sessionActions.setAllowedPaymentTypes(data.map(p => p.id))))

    dispatch(restActions.fetchRegions())
        .then(() => {
            if(getParams.region)
                return dispatch(sessionActions.setRegionById(getParams.region))
        })
        .then(() => {
            if(getState().session.region)
                return dispatch(restActions.fetchParlours(getState().session.region.id))
        })
        .then(() => {
            if(getParams.parlour)
                return dispatch(sessionActions.setParlourById(getParams.parlour))
        })
        .then(() => {
            if(getState().session.parlour)
                return dispatch(restActions.fetchFacilities(getState().session.parlour))
        })
        .then(() => {
            if(getParams.guest)
                return dispatch(authActions.fetchGuest(getParams.guest))
        })
        .then(() => {
            if(getState().auth.guest)
                return dispatch(guestsActions.addGuest(getState().auth.guest))
            else
                return dispatch(guestsActions.addGuest())
        })
        .then(() => {
            if(getState().session.parlour)
                return dispatch(facilitiesActions.setFacilities())
        })
        .then(() => {
            if(getState().session.parlour && getParams.facility)
                return dispatch(facilitiesActions.changeSelected(1, getParams.facility))
        })
        .then(() => {
            if(getParams.discount)
                return dispatch(facilitiesActions.changeSelected(1, getParams.facility))
        })
        .then(() => {
            if(getParams.date)
                return dispatch(setDate(getParams.date))
        })
        .then(() => {
            if(getParams.time)
                return dispatch(setDate(getParams.time))
        })
}

const sessionActions = {
    setRegion,
    setParlour,
    setDate,
    setRegionById,
    setParlourById,
    setStart,
    setReferralPartnerName,
    resetSession: reset,
    reset: _reset,
    setDiscount: _setDiscount,
    setMaxGuestsCount,
    setMaxDuration,
    setAllowedPaymentTypes,
    setMaxFacilitiesCount,
    init,
    setCallbackSent,
}

export default sessionActions;

