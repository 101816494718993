import React from 'react';
import Container from "@material-ui/core/Container";
import Header from "../components/Header";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import restActions from '../actions/rest'
import Grid from "@material-ui/core/Grid";
import _ from 'lodash'
import moment from "moment";

import {facilitiesActions} from "../actions/facilities";
import {Box, Button, Divider, FormControl, InputLabel, MenuItem, Select, Typography} from "@material-ui/core";
import {DatePicker} from "@material-ui/pickers";
import sessionActions from "../actions/session";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import AcceptButton from "../components/AcceptButton";
import Paper from "@material-ui/core/Paper";
import PhoneIcon from '@material-ui/icons/Phone';
import {initialState} from "../reducers/session";

const mapStateToProps = state => ({
    timings: state.rest.timings.filter(timing => {
        return moment(timing.timeStart).isAfter(moment().add(30, 'minutes'))
    }),
    parlour: state.session.parlour,
    date: state.session.date,
    start: state.session.start,
    guests: state.session.guests,
    getParamDate: state.get.date,
    notAvailableDays: state.rest.notAvailableDays,
    callbackSent: state.session.callbackSent,
    visits: state.rest.visits
});
const mapDispatchToProps = {
    getTimings: restActions.fetchTimings,
    selectTiming: facilitiesActions.selectTiming,
    createVisit: restActions.createVisit,
    setDate: sessionActions.setDate,
    setStart: sessionActions.setStart,
    getNotAvailableDays: restActions.fetchNotAvailableDays,
    sendCallback: restActions.sendCallback,
    fetchUnclosedVisitsCheck: restActions.fetchUnclosedVisitsCheck
};

class SelectTiming extends React.Component {

    state = {
        maxDate: moment().add(30, 'days'),
    }

    componentDidMount() {
        const {maxDate} = this.state;

        this.props.setStart(initialState.start)

        this.props.getTimings()

        this.props.getNotAvailableDays(
            moment().format('YYYY-MM-DD'),
            maxDate.format('YYYY-MM-DD'),
        )
    }

    handleChangeTiming = (event) => {
        this.props.selectTiming(event.target.value)
    }

    handleChangeDate = date => {
        this.props.setDate(date.format('YYYY-MM-DD'))
        this.props.setStart(initialState.start)
        this.props.getTimings()
    }

    handleAccept = () => {
        const { visits } = this.props

        if (visits) {
            this.props.fetchUnclosedVisitsCheck()
        } else {
            this.props.createVisit()
        }
    }

    handleSendCallback = () => {
        this.props.sendCallback()
    }

    filterDates = date => {
        const {notAvailableDays} = this.props;
        const currentDay = notAvailableDays.find((item) => {
            return moment(item.day).diff(date, 'days') === 0;
        })
        return !!currentDay;
    }


    render() {
        const {timings, date, start, callbackSent} = this.props;
        const {maxDate} = this.state;

        return (
            <Container maxWidth="sm" disableGutters>
                <Header allowedGoBack/>
                <Paper variant="outlined" square style={{padding: 20}}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        style={{padding: 20}}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <DatePicker
                                    label="День"
                                    autoOk
                                    openTo="date"
                                    value={date}
                                    maxDate={maxDate}
                                    disablePast={true}
                                    shouldDisableDate={this.filterDates}
                                    onChange={this.handleChangeDate}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {!_.isEmpty(timings) ?
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Время</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={start}
                                            onChange={this.handleChangeTiming}
                                        >
                                            {timings.map((timing) =>
                                                <MenuItem key={timing.timeStart}
                                                          value={timing.timeStart}>{moment(timing.timeStart).format('HH:mm')}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                    :
                                    <Typography
                                        align={'center'}
                                        color={'textSecondary'}
                                    >
                                        Нет доступных временных интервалов
                                    </Typography>
                                }
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <Divider variant="middle" style={{margin: 20}}/>
                                {
                                    callbackSent ?
                                        <>
                                            <Typography align={"center"} style={{marginBottom: 20}}>
                                                Заявка принята, скоро оператор свяжется с вами.
                                            </Typography>
                                        </>
                                        :
                                            <>
                                                <Typography align={"center"} style={{marginBottom: 20}}>
                                                    Не смогли подобрать удобное время? Наши операторы помогут Вам!
                                                </Typography>
                                                <Box display="flex" justifyContent="center">
                                                    <Button
                                                        variant={"outlined"}
                                                        color="primary"
                                                        endIcon={<PhoneIcon/>}
                                                        onClick={this.handleSendCallback}
                                                    >
                                                        Заказать звонок
                                                    </Button>
                                                </Box>
                                            </>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
                <AcceptButton
                    variant={"contained"}
                    color="primary"
                    fullWidth
                    endIcon={<NavigateNextIcon/>}
                    onClick={this.handleAccept}
                    disabled={!this.props.start || 0 === this.props.start.length}
                >
                    Продолжить
                </AcceptButton>
            </Container>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SelectTiming));
