import {getActionTypes} from "../actions/get";

const initialState = {
    region: null,
    parlour: null,
    guestId: null,
    discount: null,
    date: null,
    facility: null,
}
export default function get(state = initialState, action) {
    switch (action.type) {
        case getActionTypes.SET_GET_PARAMS:
            return {
                ...action.getParams,
            };

        case getActionTypes.SET_FACILITY:
            return {
                ...state,
                facility: action.facility
            };

        case getActionTypes.RESET:
            return initialState

        default:
            return state;
    }
}

