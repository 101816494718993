import React from 'react';
import Container from "@material-ui/core/Container";
import Header from "../components/Header";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import restActions from '../actions/rest';
import sessionActions from '../actions/session';
import {push} from 'connected-react-router';

const mapStateToProps = state => ({
    parlour: state.session.parlour,
    parlours: state.rest.parlours,
});
const mapDispatchToProps = {
    setParlourById: sessionActions.setParlourById,
    fetchParlours: restActions.fetchParlours,
    push: push,
    setRegion: sessionActions.setRegion
};

class SelectParlour extends React.Component {

    componentDidMount() {
        this.props.fetchParlours()
    }

    handleSelectParlour  = (event) => {
        this.props.setParlourById(event.currentTarget.dataset.parlourId)
    }

    handleGoBack = () => {
        this.props.setRegion(null)
        this.props.push('/regions')
    }

    render() {
        const {parlours} = this.props;

        return (
            <Container maxWidth="sm" disableGutters>
                <Header allowedGoBack goBackFunction={this.handleGoBack}/>
                <List component="nav">
                    {
                        parlours ? parlours.map(parlour =>
                            <ListItem key={parlour.id} button>
                                <ListItemText primary={parlour.name} onClick={this.handleSelectParlour} data-parlour-id={parlour.id} />
                            </ListItem>
                        ) : null
                    }
                </List>
            </Container>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SelectParlour));
