import React from 'react';
import ReactDOM from 'react-dom';
import {createStore, combineReducers, applyMiddleware} from 'redux';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';
import {reducer as formReducer} from 'redux-form';
import {composeWithDevTools} from 'redux-devtools-extension';
import {createBrowserHistory} from 'history';
import {persistStore, persistReducer} from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import storage from 'redux-persist/lib/storage'
import {
    ConnectedRouter,
    connectRouter,
    routerMiddleware,
} from 'connected-react-router';
import { YMInitializer } from 'react-yandex-metrika';
import CssBaseline from '@material-ui/core/CssBaseline';
import {ThemeProvider} from '@material-ui/core/styles';
import theme from './theme';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import * as serviceWorker from './serviceWorker';

import restReducer from './reducers/rest';
import sessionReducer from './reducers/session';
import errorReducer from './reducers/error';
import getReducer from './reducers/get';
import authReducer from './reducers/auth';
import guestsReducer from './reducers/guests';
import facilitiesReducer from './reducers/facilities';
import paymentReducer from './reducers/payment';
import ErrorMessage from './components/ErrorMessage';
import App from "./App";
import moment from "moment";
import 'moment/locale/ru';

moment.locale('ru')

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth']
}

const history = createBrowserHistory();
const store = createStore(
    persistReducer(persistConfig, combineReducers({
        router: connectRouter(history),
        form: formReducer,
        rest: restReducer,
        session: sessionReducer,
        error: errorReducer,
        get: getReducer,
        auth: authReducer,
        guests: guestsReducer,
        facilities: facilitiesReducer,
        payment: paymentReducer,
    })),
    composeWithDevTools(applyMiddleware(routerMiddleware(history), thunk))
);

const persistor = persistStore(store)


ReactDOM.render(
    <React.Suspense fallback={<div>Loading...</div>}>
        <PersistGate loading={null} persistor={persistor}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <YMInitializer accounts={[41482809]} />
                    <Provider store={store}>
                        <ConnectedRouter history={history}>
                            <App class={"test"}/>
                            <ErrorMessage/>
                        </ConnectedRouter>
                    </Provider>
                </ThemeProvider>
            </MuiPickersUtilsProvider>
        </PersistGate>
    </React.Suspense>,
    document.querySelector('#root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
