import { createSelector } from 'reselect'
import _ from 'lodash'

export const getFacilities = (state, props) =>
    state.facilities[props.guest.key]

export const getPairedFacilities = createSelector(
    [ getFacilities ],
    (facilities) => {
        return !_.isEmpty(facilities) ? facilities.filter(f => f.guestsCount === 2) : []
    }
)

export const getMassageFacilities = createSelector(
    [ getFacilities ],
    (facilities) => {
        return !_.isEmpty(facilities) ? facilities.filter(f => f.typeText === "Массаж") : []
    }
)

export const getProgramFacilities = createSelector(
    [ getFacilities ],
    (facilities) => {
        return !_.isEmpty(facilities) ? facilities.filter(f => f.typeText === "Спа-программа") : []
    }
)

export const getSelectedFacilities = createSelector(
    [ getFacilities ],
    (facilities) => {
        return !_.isEmpty(facilities) ? facilities.filter(f => f.selected) : []
    }
)

export default getSelectedFacilities