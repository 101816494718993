export const errorActionTypes = {
    SET_ERROR_MESSAGE: '[error] set error',
    HIDE_ERROR: '[error] hide error',
    SHOW_ERROR: '[error] show',
    CLEAR_ERROR: '[error] clear error'
}

const setErrorMessage = errorMessage => ({
    type: errorActionTypes.SET_ERROR_MESSAGE,
    errorMessage,
})

const hideError = () => ({
    type: errorActionTypes.HIDE_ERROR,
})

const showError = () => ({
    type: errorActionTypes.SHOW_ERROR,
})

const clearError = () => ({
    type: errorActionTypes.CLEAR_ERROR,
})

const error = (errorMessage) => (dispatch) => {
    dispatch(clearError())
    dispatch(setErrorMessage(errorMessage))
    dispatch(showError())
}


export const errorActions = {
    error,
    hideError
}

export default errorActions;