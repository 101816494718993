import {authActionTypes} from "../actions/auth";

const initialState = {
    isSent: false,
    isVerified: false,
    guest: null,
}

export default function auth(state = initialState, action) {
    switch (action.type) {
        case authActionTypes.SET_SENT:
            return {
                ...state,
                isSent: action.isSent,
            };
        case authActionTypes.SET_VERIFIED:
            return {
                ...state,
                isVerified: action.isVerified,
            };
        case authActionTypes.SET_GUEST:
            return {
                ...state,
                guest: action.guest,
            };

        case authActionTypes.RESET:
            return initialState;
        default:
            return state;
    }
}

