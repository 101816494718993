import {paymentActionTypes} from "../actions/payment";
import {defaultCurrency} from "../services/utils";

const initialState = {
    paymentType: {
        id: "cashless",
        text: "Безналичные"
    },
    discount: {},
    payDoc: {},
    total: {val: 0, cur: defaultCurrency},
    price: {val: 0, cur: defaultCurrency},
    paymentLink: null,
}

export default function payment(state = initialState, action) {
    switch (action.type) {
        case paymentActionTypes.SET_PAYMENT_TYPE:
            return {
                ...state,
                paymentType: action.paymentType
            }
        case paymentActionTypes.SET_DISCOUNT:
            return {
                ...state,
                discount: action.discount
            }
        case paymentActionTypes.SET_PAY_DOC:
            return {
                ...state,
                payDoc: action.payDoc
            }
        case paymentActionTypes.SET_TOTAL:
            return {
                ...state,
                total: action.total
            }
        case paymentActionTypes.SET_PRICE:
            return {
                ...state,
                price: action.price
            }
        case paymentActionTypes.SET_PAYMENT_LINK:
            return {
                ...state,
                paymentLink: action.paymentLink
            }
        case paymentActionTypes.RESET:
            return initialState
        default:
            return state;
    }
}

