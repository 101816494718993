import React from 'react';
import errorActions from "../actions/error";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import restActions from "../actions/rest";
import IconButton from "@material-ui/core/IconButton";
import CardHeader from "@material-ui/core/CardHeader";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import paymentActions from "../actions/payment";

const mapStateToProps = state => ({
    error: state.error.message,
    isOpen: state.error.isOpen,
    payment: state.payment,
});
const mapDispatchToProps = {
    setError: errorActions.setError,
    hideError: errorActions.hideError,
    activatePayDoc: restActions.activatePayDoc,
    changePayDoc: paymentActions.changePayDoc
};

class PayDocCard extends React.PureComponent {

    state = {
        menuIsOpen: false,
        menuAnchorEl: null,
    }

    handleOpenMenu = (event) => {
        this.setState({
            menuIsOpen: true,
            menuAnchorEl: event.currentTarget
        })
    }

    handleCloseMenu = (event) => {
        switch (event.currentTarget.dataset.action) {
            case 'refill':
                break
            default:
        }

        this.setState({
            menuIsOpen: false,
            menuAnchorEl: null,
        })
    }

    handleActivate = () => {
        this.props.activatePayDoc(`${this.props.payDoc.num}#${this.props.payDoc.pin}`)
    }

    handleUse = () => {
        this.props.changePayDoc(this.props.payDoc.id)
        this.props.handleSelect()
    }

    render() {
        const {payDoc, payment} = this.props
        const {menuIsOpen, menuAnchorEl} = this.state

        return (

            <Card variant="outlined">
                <CardHeader
                    action={
                        <IconButton onClick={this.handleOpenMenu}>
                            <MoreVertIcon/>
                        </IconButton>
                    }
                    title={payDoc.num}
                    subheader={payDoc.status}
                />
                <CardContent>
                    <Typography variant="h5" component="h2">
                        {payDoc.balanceText}
                    </Typography>
                </CardContent>
                <CardActions>
                    {payDoc.statusId === 'sold' ?
                        <Button
                            size="small"
                            color={"primary"}
                            onClick={this.handleActivate}
                            fullWidth
                            variant={"contained"}
                        >
                            Активировать
                        </Button> : null
                    }
                    {payDoc.statusId === 'active' && payDoc.balance >= payment.total.val ?
                        <Button
                            size="small"
                            color={"primary"}
                            variant={"contained"}
                            fullWidth
                            onClick={this.handleUse}
                        >
                            Использовать
                        </Button> : null
                    }
                    {payDoc.balance < payment.total.val ?
                        <Typography
                            align={'center'}
                            color={'textSecondary'}
                        >
                            Не достаточно средств
                        </Typography> : null
                    }
                </CardActions>

                <Menu
                    anchorEl={menuAnchorEl}
                    keepMounted
                    open={menuIsOpen}
                    onClose={this.handleCloseMenu}
                >
                    <MenuItem onClick={this.handleCloseMenu} disabled data-action="refill">Пополнить</MenuItem>
                </Menu>
            </Card>
        );
    }
}

PayDocCard.propTypes = {
    payDoc: PropTypes.object.isRequired,
    handleSelect: PropTypes.func
}

PayDocCard.defaultProps = {
    handleSelect: () => console.log('pay doc selected')
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PayDocCard));