import React from 'react';
import Container from "@material-ui/core/Container";
import Header from "../components/Header";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import {formatMoney} from "../services/utils";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import moment from "moment";
import restActions from "../actions/rest";
import _ from 'lodash'
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import paymentActions from "../actions/payment";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import {push} from "connected-react-router";
import TextField from "@material-ui/core/TextField";
import sessionActions from "../actions/session";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {Link as LinkRouter} from "react-router-dom";

const mapStateToProps = state => ({
    facilities: state.facilities,
    guests: state.guests,
    session: state.session,
    paymentTypes: state.rest.paymentTypes,
    payment: state.payment,
    discounts: state.rest.discounts,
    allowedPaymentTypeIds: state.session.allowedPaymentTypeIds
});
const mapDispatchToProps = {
    getPaymentTypes: restActions.fetchPaymentTypes,
    changePaymentType: paymentActions.changePaymentType,
    changeDiscount: paymentActions.changeDiscount,
    push: push,
    setReferralPartnerName: sessionActions.setReferralPartnerName,
    getDiscounts: restActions.fetchDiscounts,
    updateVisit: restActions.updateVisit,
    reset: sessionActions.reset,
    generatePaymentLink: paymentActions.setPaymentLink
};

class Checkout extends React.Component {

    state = {
        tableRows: [],
        selectedDiscountId: "",
        isNotCOVID: true,
    }

    componentDidMount() {
        this.props.updateVisit()
        this.props.generatePaymentLink()

        const {guests, facilities, paymentTypes, payment} = this.props

        if (_.isEmpty(paymentTypes)) {
            this.props.getPaymentTypes()
        }

        if (!payment.paymentType || _.isEmpty(payment.paymentType)) {
            this.props.changePaymentType('cash')
        }

        this.props.getDiscounts()

        let rows = [];
        guests.forEach(guest => {
            if(!guest.isSecond) {
                facilities[guest.key].forEach(facility => {
                    if (facility.selected) {
                        if(facility.guestsCount === 2) {
                            let secondGuest = guests.find(g => g.key === facility.secondGuest);
                            rows.push({
                                guest: `${guest.name}, ${secondGuest.name}`,
                                facility: facility.name,
                                duration: `${facility.duration * 60} минут`,
                                price: formatMoney(facility.price)
                            })
                        } else {
                            rows.push({
                                guest: guest.name,
                                facility: facility.name,
                                duration: `${facility.duration * 60} минут`,
                                price: formatMoney(facility.price)
                            })
                        }
                    }
                })
            }
        })

        this.setState({
            tableRows: rows,
            selectedDiscountId: !_.isEmpty(payment.discount) ? payment.discount.id : ""
        })
    }

    componentWillUnmount() {
        this.props.updateVisit()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.payment.discount.id !== prevProps.payment.discount.id) {
            this.setState({
                selectedDiscountId: this.props.payment.discount.id
            })
        }
    }

    handleChangePaymentType = (event) => {
        this.props.changePaymentType(event.target.value)
    }

    handleChangeReferralPartner = (event) => {
        this.props.setReferralPartnerName(event.target.value)
    }

    handleChangeDiscount = (event) => {
        this.props.changeDiscount(event.target.value)
    }

    handleChangeIsNotCovid = () => {
        this.setState({
            isNotCOVID: !this.state.isNotCOVID
        })
    }

    renderButton = () => {
        const {payment} = this.props

        switch (payment.paymentType.id) {
            case 'cash':
                return <Button
                    component={LinkRouter}
                    variant="contained"
                    color="primary"
                    fullWidth={true}
                    disabled={_.isEmpty(payment.paymentType) || !this.state.isNotCOVID}
                    to={'/success'}
                >
                    Оплатить в салане
                </Button>
            case 'cashless':
                return <Button
                    variant="contained"
                    color="primary"
                    fullWidth={true}
                    disabled={_.isEmpty(payment.paymentType) || !this.state.isNotCOVID}
                    onClick={() => {
                        window.open(this.props.payment.paymentLink)
                        this.props.push('/success')
                    }}
                >
                    Оплатить
                </Button>
            case 'paydoc':
                return <Button
                    component={LinkRouter}
                    variant="contained"
                    color="primary"
                    fullWidth={true}
                    disabled={_.isEmpty(payment.paymentType) || !this.state.isNotCOVID}
                    to={'/pay-docs'}
                >
                    Выбрать сертификат
                </Button>
            default:
                return <Button
                    variant="contained"
                    color="primary"
                    fullWidth={true}
                    disabled={_.isEmpty(payment.paymentType) || !this.state.isNotCOVID}
                    onClick={() => {
                        window.open(this.props.payment.paymentLink)
                        this.props.push('/success')
                    }}
                >
                    Оплатить
                </Button>
        }
    }

    render() {
        const {tableRows, selectedDiscountId, isNotCOVID} = this.state
        const {session, paymentTypes, payment, discounts, allowedPaymentTypeIds} = this.props

        return (
            <Container maxWidth="sm" disableGutters>
                <Header/>
                <Paper variant="outlined" square style={{padding: 20}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <List dense>
                                <ListItem>
                                    <ListItemText
                                        primary={<Grid container justify={'space-between'}>
                                            <Grid item xs={6}>
                                                <Typography>Адрес:</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography align={"right"}>{session.parlour.address}</Typography>
                                            </Grid>
                                        </Grid>}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={<Grid container justify={'space-between'}>
                                            <Grid item xs={6}>
                                                <Typography>Время начала:</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    align={"right"}>{moment(session.start).format('DD MMMM HH:mm')}</Typography>
                                            </Grid>
                                        </Grid>}
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        {tableRows.map((row, key) => (
                                            <TableRow key={key}>
                                                <TableCell align="left">{row.guest}</TableCell>
                                                <TableCell align="left">{row.facility}</TableCell>
                                                <TableCell align="left">{row.duration}</TableCell>
                                                {!session.psh ?
                                                    <TableCell align="right">{row.price}</TableCell>
                                                    : null}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        {!session.psh ?
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField
                                    name={'referralPartnerName'}
                                    label={'Реферальный партнёр'}
                                    value={session.referralPartnerName}
                                    onChange={this.handleChangeReferralPartner}
                                    fullWidth={true}
                                />
                            </Grid> : null}
                        {discounts && discounts.length > 0 ? <Grid item xs={12} sm={12} md={session.psh ? 12 : 6}>
                            <TextField
                                name={'discount'}
                                label={'Скидка'}
                                select
                                value={selectedDiscountId}
                                onChange={this.handleChangeDiscount}
                                fullWidth={true}
                                disabled={session.psh}
                            >
                                <MenuItem key={0} value={null}>
                                    Без скидки
                                </MenuItem>
                                {discounts.map(discount =>
                                    <MenuItem key={discount.id} value={discount.id}>
                                        {discount.name}
                                    </MenuItem>
                                )}
                            </TextField>
                        </Grid> : null}
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Тип оплаты</InputLabel>
                                <Select
                                    fullWidth
                                    value={!_.isEmpty(payment.paymentType) ? payment.paymentType.id : 'cashless'}
                                    onChange={this.handleChangePaymentType}
                                    disabled={session.psh}
                                >
                                    {paymentTypes.filter(paymentType => allowedPaymentTypeIds.includes(paymentType.id))
                                        .map(paymentType => {
                                            let Icon;
                                            switch (paymentType.id) {
                                                case 'cash':
                                                    Icon = AttachMoneyIcon
                                                    break
                                                case 'cashless':
                                                    Icon = CreditCardIcon
                                                    break
                                                case 'paydoc':
                                                    Icon = CardGiftcardIcon
                                                    break
                                                default:
                                                    Icon = CreditCardIcon
                                            }
                                            return <MenuItem key={paymentType.id} value={paymentType.id}>
                                                <Box display="flex">
                                                    <ListItemIcon>
                                                        <Icon fontSize="small"/>
                                                    </ListItemIcon>
                                                    <Typography>{paymentType.text}</Typography>
                                                </Box>
                                            </MenuItem>
                                        })}
                                </Select>
                            </FormControl>
                        </Grid>
                        {!session.psh ? <React.Fragment>
                            <Grid item xs={6}>
                                <Typography>Итог</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography align={"right"}>{formatMoney(payment.total)}</Typography>
                            </Grid>
                        </React.Fragment> : null}
                        <Grid item xs={12}>
                            {this.renderButton()}
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl>
                                <FormControlLabel
                                    control={<Checkbox checked={isNotCOVID}
                                                       onChange={this.handleChangeIsNotCovid}
                                                       name="isNoCovid"/>}
                                    label={"Я подтверждаю что не болен/болел COVID-19"}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant={"body2"}
                                align={'center'}
                                color={'textSecondary'}
                            >
                                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                <a href="https://tvoitaispa.ru/upload/Protivopokazania_2018.pdf" target="_blank">Ознакомьтесь
                                    с противопоказаниями</a>
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Checkout));
