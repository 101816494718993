import * as queryString from "query-string-object";

export const paymentActionTypes = {
    SET_PAYMENT_TYPE: '[payment] set payment type',
    SET_DISCOUNT: '[payment] set discount',
    SET_PAY_DOC: '[payment] set paydoc',
    SET_PRICE: '[payment] set price',
    SET_TOTAL: '[payment] set total',
    SET_PAYMENT_LINK: '[payment] set link',
    RESET: '[payment] reset',
}

const setPaymentType = (paymentType) => ({
    type: paymentActionTypes.SET_PAYMENT_TYPE,
    paymentType
})

const setDiscount = (discount) => ({
    type: paymentActionTypes.SET_DISCOUNT,
    discount
})

const setPayDoc = (payDoc) => ({
    type: paymentActionTypes.SET_PAY_DOC,
    payDoc
})

const setTotal = (total) => ({
    type: paymentActionTypes.SET_TOTAL,
    total
})

const setPrice = (price) => ({
    type: paymentActionTypes.SET_PRICE,
    price
})

const setPaymentLink = (paymentLink) => ({
    type: paymentActionTypes.SET_PAYMENT_LINK,
    paymentLink
})

const reset = () => ({
    type: paymentActionTypes.RESET
})

const _setPaymentLink = () => (dispatch, getState) => {
    const state = getState()
    const paymentLink = process.env.REACT_APP_HOST_PAY + '?' + queryString.stringify({
        from_type: 'visit',
        user_name: state.auth.guest.fullName,
        user_phone: state.auth.guest.phone,
        free_price: state.payment.total.val,
        user_email: state.auth.guest.email,
        visit: state.rest.visits.mainVisit,
        discount: state.payment.discount.id
    })
    dispatch(setPaymentLink(paymentLink))
}

const changePaymentType = (paymentTypeId) => (dispatch, getState) => {
    const state = getState()
    dispatch(setPaymentType(state.rest.paymentTypes.find(paymentType => paymentType.id === paymentTypeId)))
    dispatch(_setPaymentLink())
}

const changeDiscount = (discountId = null) => (dispatch, getState) => {
    const state = getState()

    if(discountId) {
        const discount = state.rest.discounts.find(d => d.id === discountId)
        dispatch(setTotal(discount.total))
        dispatch(setDiscount(discount))
    } else {
        dispatch(setTotal(state.payment.price))
        dispatch(setDiscount({}))
    }
    dispatch(_setPaymentLink())
}

const changePayDoc = (payDocId) => (dispatch, getState) => {
    const state = getState()
    const payDoc = state.rest.payDocs.find(p => p.id === payDocId)
    dispatch(setPayDoc(payDoc))
    dispatch(_setPaymentLink())
}

const paymentActions = {
    changePaymentType,
    changeDiscount,
    changePayDoc,
    setTotal,
    setPaymentLink: _setPaymentLink,
    reset,
    setPrice,
}

export default paymentActions